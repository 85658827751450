<template>
  <v-card class="ma-2">
    <v-progress-linear
      :active="isLoading"
      :indeterminate="isLoading"
      absolute
      top
    ></v-progress-linear>
    <v-alert
      v-model="showError"
      closable
      icon="$error"
      title="Error"
      type="error"
    >
      <div>{{ errorText }}</div>
    </v-alert>
    <v-container fluid>
      <!-- Text input filter -->
      <v-row class="pt-3">
        <v-col xs="auto" class="mr-0">
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            :label="$t('common.filters.filter')"
            :disabled="isLoading"
            hide-details
            class="ma-0 pa-0"
          ></v-text-field>
        </v-col>
        <v-col cols="auto" class="mx-0 px-0">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                @click="onRefresh"
                :loading="isLoading"
                :disabled="isLoading"
              >
                <v-icon>mdi-cached</v-icon>
                <template v-slot:loader>
                  <span class="refreshLoader">
                    <v-icon>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </template>
            <span>{{ $t("common.actions.refresh") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="parameters"
        :search="search"
        :custom-filter="customFilter"
        item-key="parameterNo"
        fixed-header
        dense
        :items-per-page-options="[100, 200, 500, -1]"
        :items-per-page="100"
      >
      </v-data-table>
    </v-card-text>
    <v-snackbar
      v-model="showSnackbar"
      :timeout="3000"
      :color="snackbarColor"
      top
    >
      {{ snackbarText }}
    </v-snackbar>
  </v-card>
</template>

<script>
import fetchClient from "@/utils/fetchClient"

export default {
  props: ["machine", "paramFile"],
  components: {},
  data: () => ({
    isLoading: false,
    search: "",
    showError: false,
    errorText: null,
    errorItems: [],

    showSnackbar: false,
    snackbarText: "",
    snackbarColor: "success",
    parameters: []
  }),
  async created() {
    await this.fetchParameterDetails()
  },
  computed: {
    headers() {
      let hdrs = [
        {
          text: "#",
          value: "parameterNo",
          sortable: true
        },
        {
          text: "Category",
          value: "category",
          sortable: true
        },
        {
          text: "Device",
          value: "device",
          sortable: true
        },
        {
          text: "Description",
          value: "description",
          sortable: true
        },
        {
          text: "Value",
          value: "value",
          sortable: true
        },
        {
          text: "Unit",
          value: "unit",
          sortable: true
        }
      ]
      return hdrs
    }
  },
  methods: {
    async fetchParameterDetails() {
      this.clearError()

      if (!this.machine || !this.paramFile) {
        return
      }

      try {
        this.isLoading = true
        this.parameters = []
        // "name":"B70-990011-factory-tuned.csv"
        const url = `/api/v1/tenants/${this.machine.tenantUid}/machines/${this.machine.serialNo}/parameterFiles/${this.paramFile.name}/contents`
        this.parameters = await fetchClient(this.$i18n).getDataOrThrow(url)
      } catch (err) {
        this.showErrorMessage(
          `failed to fetch parameter file contents: ${err.message}`
        )
      } finally {
        this.isLoading = false
      }
    },
    async onRefresh() {
      await this.fetchParameterDetails()
    },
    clearError() {
      this.showError = false
      this.errorText = ""
    },
    showErrorMessage(msg) {
      this.showError = true
      this.errorText = msg
    },
    customFilter(value, query, item) {
      const ss = query.toLowerCase()

      return (
        `${item.parameterNo}`.startsWith(query) ||
        item.category.toLowerCase().includes(ss) ||
        item.device.toLowerCase().includes(ss) ||
        item.description.toLowerCase().includes(ss)
      )
    }
  }
}
</script>
