<template>
  <v-card flat :disabled="disabled">
    <!-- Filter selection -->
    <v-card-text class="pl-0 pt-0">
      <CheckItemGroupsFilterMenu
        :filterName="$t('machines.dataTable.headers.product')"
        :availableItems="availableProducts"
        :selectedItems.sync="products"
      />
      <CheckItemsFilterMenu
        :filterName="$t('machines.dataTable.headers.brokkConnect')"
        :availableItems="availableConnectTypes"
        :selectedItems.sync="connectTypes"
      />
      <CheckItemsFilterMenu
        :filterName="$t('machines.dataTable.headers.serviceStatus')"
        :availableItems="availableStatuses"
        :selectedItems.sync="statuses"
      />
      <EngineHoursFilterMenu
        :filterName="$t('machines.dataTable.headers.engineHours')"
        :availableHoursRange="availableHoursRange"
        :selectedHoursRange.sync="engineHoursRange"
      />
    </v-card-text>
    <!-- Active filters -->
    <v-row>
      <v-col cols="12" v-if="hasFilter">
        Filters:
        <span>
          <template v-if="products?.length > 0">
            <v-chip class="ma-2" color="primary" outlined>
              {{ $t("machines.dataTable.headers.product") }}:
            </v-chip>
            <v-chip
              v-for="product of products"
              :key="product.id"
              class="ma-2"
              close
              color="primary"
              outlined
              @click:close="onRemoveProductsFilter(product)"
            >
              {{ product.label }}
            </v-chip>
            <v-chip
              class="ma-2"
              close
              color="primary"
              outlined
              @click:close="onRemoveProductsFilter"
            >
              {{ $t("machines.actions.clearProductFilters") }}
            </v-chip>
          </template>
        </span>
        <span>
          <v-chip
            v-if="connectTypesFilterLabel.length > 0"
            class="ma-2"
            close
            color="primary"
            outlined
            @click:close="onRemoveConnectTypesFilter"
          >
            {{ connectTypesFilterLabel }}
          </v-chip>
        </span>
        <span>
          <v-chip
            v-if="statusesFilterLabel.length > 0"
            class="ma-2"
            close
            color="primary"
            outlined
            @click:close="onRemoveStatusFilter"
          >
            {{ statusesFilterLabel }}
          </v-chip>
        </span>
        <span>
          <v-chip
            v-if="engineHoursFilterLabel.length > 0"
            class="ma-2"
            close
            color="primary"
            outlined
            @click:close="onRemoveEngineHoursFilter"
          >
            {{ engineHoursFilterLabel }}
          </v-chip>
        </span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import CheckItemsFilterMenu from "@/components/filters/CheckItemsFilterMenu"
import EngineHoursFilterMenu from "@/components/filters/EngineHoursFilterMenu"
import CheckItemGroupsFilterMenu from "./CheckItemGroupsFilterMenu.vue"

export default {
  props: [
    "availableProducts",
    "selectedProducts",

    "availableConnectTypes",
    "selectedConnectTypes",

    "availableStatuses",
    "selectedStatuses",

    "availableHoursRange",
    "selectedHoursRange",

    "disabled",
    "isFiltered"
  ],
  components: {
    CheckItemsFilterMenu,
    EngineHoursFilterMenu,
    CheckItemGroupsFilterMenu
  },
  data() {
    return {
      products: [],
      connectTypes: [],
      statuses: [],
      engineHoursRange: [-1, 0]
    }
  },
  created() {
    this.products = this.selectedProducts
    this.connectTypes = this.selectedConnectTypes
    this.statuses = this.selectedStatuses
    this.engineHoursRange = this.selectedHoursRange
  },
  computed: {
    hasFilter() {
      const isFiltered =
        this.products.length > 0 ||
        this.connectTypes.length > 0 ||
        this.statuses.length > 0 ||
        this.engineHoursRange[0] > -1
      this.$emit("update:isFiltered", isFiltered)
      return isFiltered
    },
    connectTypesFilterLabel() {
      const names = this.connectTypes?.map(p => p.label)
      if (names.length > 0) {
        return `${this.$t(
          "machines.dataTable.headers.brokkConnect"
        )}: ${names.join(", ")}`
      }
      return ""
    },
    statusesFilterLabel() {
      const names = this.statuses?.map(p => p.label)
      if (names.length > 0) {
        return `${this.$t(
          "machines.dataTable.headers.serviceStatus"
        )}: ${names.join(", ")}`
      }
      return ""
    },
    engineHoursFilterLabel() {
      if (this.engineHoursRange[0] > -1) {
        return `${this.$t("machines.dataTable.headers.engineHours")}: ${
          this.engineHoursRange[0]
        }...${this.engineHoursRange[1]} h`
      }
      return ""
    }
  },
  methods: {
    onRemoveProductsFilter(productId) {
      if (productId) {
        this.products.splice(this.products.indexOf(productId), 1)
      } else {
        this.products = []
      }
      this.$emit("update:selectedProducts", this.products)
      this.$emit("filterChanged", {})
    },
    onRemoveConnectTypesFilter() {
      this.connectTypes = []
      this.$emit("update:selectedConnectTypes", this.connectTypes)
      this.$emit("filterChanged", {})
    },
    onRemoveStatusFilter() {
      this.statuses = []
      this.$emit("update:selectedStatuses", this.statuses)
      this.$emit("filterChanged", {})
    },
    onRemoveEngineHoursFilter() {
      this.engineHoursRange = [-1, 0]
      this.$emit("update:selectedHoursRange", this.engineHoursRange)
      this.$emit("filterChanged", {})
    }
  },
  watch: {
    products(newValue) {
      this.$emit("update:selectedProducts", newValue)
      this.$emit("filterChanged", {})
    },
    connectTypes(newValue) {
      this.$emit("update:selectedConnectTypes", newValue)
      this.$emit("filterChanged", {})
    },
    engineHoursRange(newValue) {
      this.$emit("update:selectedHoursRange", newValue)
      this.$emit("filterChanged", {})
    },
    statuses(newValue) {
      this.$emit("update:selectedStatuses", newValue)
      this.$emit("filterChanged", {})
    }
  }
}
</script>

<style></style>
