export default {
  documents: {
    ce: {
      subTitle: "Documentos CE para Brokk {productId}",
      title: "CE"
    },
    diagrams: {
      subTitle: "Diagramas para Brokk {productId}",
      title: "Diagramas"
    },
    manuals: {
      subTitle: "Manuales para Brokk {productId}",
      title: "Manuales"
    },
    menuItems: {
      returnform: "Formulario de devolución",
      serviceBulletins: "Boletines de mantenimiento",
      serviceInstructions: "Instrucciones de mantenimiento",
      serviceManuals: "Manuales de mantenimiento",
      serviceProtocols: "Protocolos de mantenimiento",
      warranty: "Reclamaciones dentro de la garantía"
    },
    returnform: {
      description1:
        "Utilice este formulario cuando devuelva productos a Brokk AB.",
      description2:
        "Antes de hacer la devolución de cualquier producto, póngase en contacto con Brokk AB para su autorización.",
      description3:
        "Los productos recibidos en Brokk AB sin autorización serán rechazados.",
      subtitle: "Formulario de devolución de productos Brokk."
    },
    serialNoRange: "N.º de serie: {range}",
    serviceBulletins: {
      action: {
        add: "Añadir boletines de mantenimiento",
        addSerials: "Añadir números de serie",
        edit: "Editar boletines de mantenimiento"
      },
      description:
        "Información sobre mejoras, inspecciones, reparaciones o modificaciones de máquinas y herramientas Brokk.",
      dialogs: {
        errors: {
          couldNotAddServiceBulletins:
            "No se han podido añadir los boletines de mantenimiento {msg}",
          couldNotRemoveServiceBulletins:
            "No se han podido eliminar los boletines de mantenimiento {msg}",
          couldNotUpdateServiceBulletins:
            "No se han podido actualizar los boletines de mantenimiento {msg}"
        }
      },
      itemName: "boletines de mantenimiento",
      messages: {
        failedToDownload: "Error al descargar {file}",
        itemAdded: "Se han añadido los boletines de mantenimiento.",
        itemUpdated: "Se han actualizado los boletines de mantenimiento.",
        updateDateInfo: "se ha actualizado {date}",
        updateDocumentInfo: "Documento {document} "
      },
      subtitle: "Documentos de los boletines de mantenimiento."
    },
    serviceDocuments: {
      action: {
        addSerials: "Añadir números de serie"
      },
      addEditItemForm: {
        descriptionLabel: "Descripción",
        descriptionPlaceholder: "Introducir descripción",
        fileuploadLabel: "Haga clic aquí para seleccionar un archivo .pdf",
        machinesLabel: "Máquinas",
        partNumberLabel: "Número de pieza",
        partNumberPlaceholder: "Introduzca el número de pieza",
        serialLabel: "Números de serie",
        serialPlaceholder: "Introduzca los números de serie separados con ','",
        uploadFilePlaceholder: "Subir un archivo"
      },
      datatable: {
        headers: {
          description: "Descripción",
          machines: "Máquinas",
          partNo: "N.º de pieza"
        }
      },
      machineSerials: "Números de serie de las máquinas"
    },
    serviceInstructions: {
      action: {
        add: "Añadir instrucción de mantenimiento",
        addSerials: "Añadir números de serie",
        edit: "Editar instrucción de mantenimiento"
      },
      datatable: {
        headers: {
          machines: "Máquinas",
          partNo: "N.º de pieza",
          service: "Mantenimiento"
        }
      },
      description:
        "Instrucciones sobre cómo instalar, mantener o ajustar varias piezas y kits.",
      dialogs: {
        errors: {
          couldNotAddServiceInstructions:
            "No se ha podido añadir la instrucción de mantenimiento {msg}",
          couldNotRemoveServiceInstructions:
            "No se ha podido eliminar la instrucción de mantenimiento {msg}",
          couldNotUpdateServiceInstructions:
            "No se ha podido actualizar la instrucción de mantenimiento {msg}"
        }
      },
      itemName: "instrucción de mantenimiento",
      machineSerials: "Números de serie de las máquinas",
      messages: {
        failedToDownload: "Error al descargar {file}",
        itemAdded: "Se han añadido las instrucciones de mantenimiento.",
        itemUpdated: "Se ha actualizado la instrucción de mantenimiento.",
        updateDateInfo: "se ha actualizado {date}",
        updateDocumentInfo: "Documento {document} "
      },
      subtitle: "Documentos de la instrucción de mantenimiento."
    },
    serviceManuals: {
      description:
        "Manuales sobre puesta a punto, mantenimiento y reparación. Solo disponible para Brokk 40, 90, 110, 150, 180, 250, 330 y SP+.",
      subtitle: "Documentos de los manuales de mantenimiento."
    },
    serviceProtocols: {
      description1:
        "Aquí puede descargar todos los protocolos de mantenimiento disponibles.",
      description2:
        "El protocolo de mantenimiento se utiliza al realizar el mantenimiento periódico según el calendario de mantenimiento incluido en el manual de Brokk.",
      subtitle: "Protocolos de mantenimiento de las máquinas Brokk."
    },
    spareParts: {
      description: "",
      includedInManual: null,
      subtitle: "Recambios",
      title: null
    },
    technicalData: {
      includedInManual:
        "Los datos técnicos están incluidos en el manual. Consulte el manual.",
      subTitle: "Datos técnicos para Brokk {productId}",
      title: "Datos técnicos"
    },
    troubleshooting: {
      includedInManual:
        "La localización de averías está incluida en el manual. Consulte el manual.",
      subTitle: "Localización de averías para Brokk {productId}",
      title: "Localización de averías"
    },
    warranty: {
      description: "",
      subtitle: "Documentos y enlaces de la garantía."
    }
  }
}
