export default {
  fleetAnalytics: {
    dashboardHeader: "Pannello di controllo di Fleet Analytics",
    dataTable: {
      headers: {
        contacts: "Contatti",
        machines: "Macchine",
        segments: "Segmenti",
        tenant: "Locatario"
      },
      machines: {
        count: "Conteggio",
        engineHours: "Lavorato",
        serviceAgreements: "Contratti di manutenzione"
      }
    },
    widgets: {
      machinesPerModel: null,
      machinesPerSegment: null,
      segments: "Utilizzo per segmento",
      utilization: "Utilizzo per modello"
    }
  }
}
