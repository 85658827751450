export default {
  documents: {
    ce: {
      subTitle: "CE documents for Brokk {productId}",
      title: "CE"
    },
    diagrams: {
      subTitle: "Diagrams for Brokk {productId}",
      title: "Diagrams"
    },
    manuals: {
      subTitle: "Manuals for Brokk {productId}",
      title: "Manuals"
    },
    menuItems: {
      returnform: "Return Form",
      serviceBulletins: "Service Bulletins",
      serviceInstructions: "Service Instructions",
      serviceManuals: "Service Manuals",
      serviceProtocols: "Service Protocols",
      warranty: "Warranty Claims"
    },
    returnform: {
      description1: "Use this form when returning goods to Brokk AB.",
      description2:
        "Before sending any goods in return contact Brokk AB for approval.",
      description3:
        "Goods received at Brokk AB without approval will be discarded.",
      subtitle: "Return form for Brokk goods."
    },
    serialNoRange: "Serial No: {range}",
    serviceBulletins: {
      action: {
        add: "Add service bulletins",
        addSerials: "Add serials",
        edit: "Edit service bulletins"
      },
      description:
        "Information covering improvement, inspections, repair or modification of Brokk machines and tools.",
      dialogs: {
        errors: {
          couldNotAddServiceBulletins: "Could not add service bulletins {msg}",
          couldNotRemoveServiceBulletins:
            "Could not remove service bulletins {msg}",
          couldNotUpdateServiceBulletins:
            "Could not update service bulletins {msg}"
        }
      },
      itemName: "service bulletins",
      messages: {
        failedToDownload: "Failed to download {file}",
        itemAdded: "Service bulletins was added.",
        itemUpdated: "Service bulletins was updated.",
        updateDateInfo: "updated {date}",
        updateDocumentInfo: "Document {document} "
      },
      subtitle: "Documents for service bulletins."
    },
    serviceDocuments: {
      action: {
        addSerials: "Add serials"
      },
      addEditItemForm: {
        descriptionLabel: "Description",
        descriptionPlaceholder: "Enter description",
        fileuploadLabel: "Click here to select a .pdf file",
        machinesLabel: "Machines",
        partNumberLabel: "Part number",
        partNumberPlaceholder: "Enter part number",
        serialLabel: "Serials",
        serialPlaceholder: "Enter serials separated with ','",
        uploadFilePlaceholder: "Upload a file"
      },
      datatable: {
        headers: {
          description: "Description",
          machines: "Machines",
          partNo: "Part No"
        }
      },
      machineSerials: "Machines serials"
    },
    serviceInstructions: {
      action: {
        add: "Add service instruction",
        addSerials: "Add serials",
        edit: "Edit service instruction"
      },
      datatable: {
        headers: {
          machines: "Machines",
          partNo: "Part No",
          service: "Service"
        }
      },
      description:
        "Instructions on how to install, maintain or adjust various parts and kits.",
      dialogs: {
        errors: {
          couldNotAddServiceInstructions:
            "Could not add service instruction {msg}",
          couldNotRemoveServiceInstructions:
            "Could not remove service instruction {msg}",
          couldNotUpdateServiceInstructions:
            "Could not update service instruction {msg}"
        }
      },
      itemName: "service instruction",
      machineSerials: "Machines serials",
      messages: {
        failedToDownload: "Failed to download {file}",
        itemAdded: "Service instructions was added.",
        itemUpdated: "Service instruction was updated.",
        updateDateInfo: "updated {date}",
        updateDocumentInfo: "Document {document} "
      },
      subtitle: "Documents for service instruction."
    },
    serviceManuals: {
      description:
        "Manuals covering service, maintenance and repair. Only available for Brokk 40, 90, 110, 150, 180, 250, 330 and SP+.",
      subtitle: "Documents for service manuals."
    },
    serviceProtocols: {
      description1: "Here you can download all available service protocols.",
      description2:
        "The service protocol is used when making the regular maintenance according to the service schedule in the Brokk manual.",
      subtitle: "Service protocols for Brokk machines."
    },
    spareParts: {
      description: "",
      includedInManual:
        "Spare parts data is included in the manual. Please check the manual.",
      subtitle: "Spare parts",
      title: "Spare parts"
    },
    technicalData: {
      includedInManual:
        "Technical data is included in the manual. Please check the manual.",
      subTitle: "Technical data for Brokk {productId}",
      title: "Technical data"
    },
    troubleshooting: {
      includedInManual:
        "Troubleshooting is included in the manual. Please check the manual.",
      subTitle: "Troubleshooting for Brokk {productId}",
      title: "Troubleshooting"
    },
    warranty: {
      description: "",
      subtitle: "Warranty documents and links."
    }
  }
}
