<template>
  <v-container class="pa-4">
    <v-row>
      <v-col cols="12">
        <v-tabs
          v-model="tab"
          background-color="white"
          color="black accent-4"
          left
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#list" v-if="showParameterList">
            <v-icon class="mr-2" small>mdi-format-list-bulleted</v-icon>
            List
          </v-tab>
          <v-tab href="#files">
            <v-icon class="mr-2" small
              >mdi-file-document-multiple-outline</v-icon
            >
            Files
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-tabs-items v-model="tab">
      <!-- LIST -->
      <v-tab-item value="list" v-if="showParameterList">
        <v-row class="mt-2">
          <v-col xs="auto" class="mr-0">
            <v-text-field
              v-model="search"
              prepend-icon="mdi-magnify"
              :label="$t('common.filters.filter')"
              hide-details
              class="ma-0 pa-0"
            ></v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  @click="refreshParameters"
                  :loading="refreshLoading"
                  :disabled="refreshLoading"
                >
                  <v-icon>mdi-cached</v-icon>
                  <template v-slot:loader>
                    <span class="refreshLoader">
                      <v-icon>mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn>
              </template>
              {{ $t("common.actions.refresh") }}
            </v-tooltip>

            <v-tooltip top v-if="canEditParameters">
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <v-btn
                    icon
                    @click="pushStashedChanges"
                    :disabled="!hasStashedChanges"
                  >
                    <v-icon>mdi-content-save</v-icon>
                    <template v-slot:loader>
                      <span>
                        <v-icon>mdi-content-save</v-icon>
                      </span>
                    </template>
                  </v-btn>
                </span>
              </template>
              {{ $t("common.actions.applyChanges") }}
            </v-tooltip>
          </v-col>
        </v-row>

        <v-data-table
          class="mt-2"
          v-if="machineParameters"
          :headers="headers"
          :items="machineParameters"
          :loading="isLoading"
          :search="search"
          @click:row="rowClickHandler"
        >
          <template v-slot:[`item.state`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div class="d-flex justify-center">
                  <v-icon v-on="on" :color="getColor(item.state)">{{
                    getIcon(item.state)
                  }}</v-icon>
                </div>
              </template>
              <span>{{ getToolTip(item.state) }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.rawValue`]="{ item }">
            <span class="mr-2">{{ item.rawValue }}</span>
            <span v-if="shouldHaveTooltip(item)">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon small v-on="on">mdi-information</v-icon>
                </template>
                <div v-for="txt in getParameterText(item)" :key="txt">
                  {{ txt }}
                </div>
              </v-tooltip>
            </span>
          </template>
        </v-data-table>
      </v-tab-item>

      <!-- PARAMETER FILES -->
      <v-tab-item value="files">
        <ParameterFiles :machine="machine" />
      </v-tab-item>
    </v-tabs-items>
    <EditParameterDialog
      v-if="parameterDialog"
      show="true"
      :parameterDialog="parameterDialog"
    />
  </v-container>
</template>

<script>
import EditParameterDialog from "../../dialogs/EditParameterDialog"
import ParameterFiles from "../../components/machines/ParameterFiles"
import colors from "vuetify/lib/util/colors"

export default {
  name: "MachineParameters",
  props: ["machine"],
  components: { EditParameterDialog, ParameterFiles },

  data: () => ({
    tab: "list",
    parameters: [],
    search: "",
    parameterState: {
      DEFAULT: "default",
      APPLIED: "applied",
      PENDING: "pending",
      MODIFIED: "modified"
    },
    parameterDialog: null,

    refreshLoading: false
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("machines.parameters.dataTable.headers.id"),
          align: "center",
          sortable: true,
          filterable: true,
          value: "parameterId",
          width: "5%"
        },
        {
          text: this.$t("machines.parameters.dataTable.headers.number"),
          align: "right",
          sortable: true,
          filterable: true,
          value: "oldParameter",
          width: "8%"
        },
        {
          text: this.$t("machines.parameters.dataTable.headers.category"),
          align: "left",
          sortable: true,
          filterable: true,
          value: "category"
        },
        {
          text: this.$t("machines.parameters.dataTable.headers.device"),
          align: "left",
          sortable: true,
          filterable: true,
          value: "device"
        },
        {
          text: this.$t("machines.parameters.dataTable.headers.description"),
          align: "start",
          sortable: true,
          filterable: true,
          value: "description"
        },
        {
          text: this.$t("machines.parameters.dataTable.headers.value"),
          align: "right",
          sortable: false,
          filterable: false,
          value: "rawValue"
        }
      ]
    },
    machineParameters() {
      const mergedParameters = [
        ...this.$store.getters.machineParameters,
        ...this.$store.getters.stashedChanges.filter(
          x => x.machineId == this.machine.serialNo
        )
      ]

      const uniqueById = [
        ...new Map(
          mergedParameters.map(item => [item.parameterId, item])
        ).values()
      ]
      return uniqueById
    },
    hasStashedChanges() {
      return this.$store.getters.stashedChanges.length > 0
    },
    isLoading() {
      return this.$store.getters.isLoadingParameters
    },
    showParameterList() {
      return (
        this.$store.getters.isAdmin ||
        this.$store.getters.isService ||
        this.$store.getters.isProduction
      )
    },
    canEditParameters() {
      // This is a feature toggle to hide the save button and to disable click handler on table
      // Should be mapped to this.$store.getters.canEditParameters
      return false
    },
    specialFuncsMetadata() {
      return this.$store.getters.specialFuncsMetadata
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    getToolTip(state) {
      switch (state) {
        case this.parameterState.MODIFIED:
          return this.$t("machines.messages.unsavedChanges")
        case this.parameterState.APPLIED:
          return this.$t("machines.messages.changesApplied")
        case this.parameterState.PENDING:
          return this.$t("machines.messages.applyChangesPending")
        default:
          return ""
      }
    },
    getColor(state) {
      switch (state) {
        case this.parameterState.MODIFIED:
          return colors.amber.base
        case this.parameterState.APPLIED:
          return colors.green.base
        case this.parameterState.PENDING:
          return colors.amber.base
        default:
          return ""
      }
    },
    getIcon(state) {
      switch (state) {
        case this.parameterState.MODIFIED:
          return "mdi-lead-pencil"
        case this.parameterState.APPLIED:
          return "mdi-check"
        case this.parameterState.PENDING:
          //return "mdi-lan-pending"
          return "mdi-clock-alert"
        default:
          return ""
      }
    },
    getParameterText(item) {
      if (item.parameterId === "P9") {
        // Special functions code is a bit-mask
        let mask = Number.parseInt(item.rawValue)
        let txts = []

        if (mask > 0) {
          this.specialFuncsMetadata.forEach(opt => {
            if (mask & opt.value) {
              txts.push(opt.description)
            }
          })
        } else {
          txts.push("None")
        }

        return txts
      } else if (item.parameterId === "P18" || item.parameterId === "P84") {
        // Service mode is a bit-mask
        let mask = Number.parseInt(item.rawValue)
        let txts = []

        if ((mask & 0x01) === 0x01) {
          txts.push("Active")
        } else {
          txts.push("Inactive")
        }

        return txts
      }
      return [item.rawValue]
    },
    shouldHaveTooltip(item) {
      return (
        item.parameterId === "P9" ||
        item.parameterId === "P18" ||
        item.parameterId === "P84"
      )
    },
    pushStashedChanges() {
      this.$store.dispatch("pushStashedChanges", {
        serialNo: this.machine.serialNo,
        tenantUid: this.machine.tenantUid
      })
    },
    fetchData() {
      this.$store.dispatch("fetchMachineParameters", {
        tenantUid: this.machine.tenantUid,
        serialNo: this.machine.serialNo,
        langCode: "en"
      })

      // Load meta data for special functions
      this.$store.dispatch("loadSpecialFuncsMetadata", "en")

      this.$store.dispatch("clearStashedChanges")
    },
    refreshParameters() {
      this.refreshLoading = true
      this.fetchData()
      setTimeout(() => {
        this.refreshLoading = false
      }, 1000)
    },
    rowClickHandler(clickedItem) {
      if (!this.canEditParameters) {
        return
      }
      let dialog = {
        item: { ...clickedItem },
        buttons:
          clickedItem.editMode !== "never"
            ? [
                {
                  label: this.$t("common.actions.save"),
                  action: () => {
                    this.$store.dispatch("updateStashedChanges", {
                      ...this.$data.parameterDialog.item,
                      machineId: this.machine.serialNo
                    })
                  }
                }
              ]
            : []
      }
      this.$data.parameterDialog = dialog
    }
  }
}
</script>
<style>
.stateCol {
  width: 130px;
}
.v-data-table__wrapper tr {
  cursor: pointer;
}
.v-data-table__wrapper th {
  cursor: default;
}
.refreshLoader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
</style>
