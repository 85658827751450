<template>
  <v-card>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      top
    ></v-progress-linear>

    <v-data-table
      class="mt-2"
      :no-results-text="$t('reports.dataTable.noResultsText')"
      :headers="headers"
      :items="fileList"
      item-key="key"
      :class="{ disabled: loading }"
      sort-by="modified"
      sort-desc
      :show-expand="showExpand"
      single-expand
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <ParameterListView :machine="machine" :paramFile="item" />
        </td>
      </template>

      <template v-slot:[`item.modified`]="{ value }">
        <span class="grab-downloads-item-date">{{ value }}</span>
      </template>

      <template v-slot:[`item.icon`]="{ item }">
        <v-tooltip top v-if="canDownload">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" @click="downloadItem(item)">{{
              item.icon
            }}</v-icon>
          </template>
          <span>{{ $t("common.actions.download") }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-progress-circular
          v-if="item.downloading"
          :size="20"
          :width="2"
          indeterminate
        />

        <template v-else-if="$vuetify.breakpoint['xs']">
          <v-btn v-if="canDownload" @click="downloadItem(item)" depressed small>
            {{ $t("common.actions.download") }}
          </v-btn>
        </template>

        <v-menu v-else left>
          <template v-slot:activator="{ on: menu }">
            <v-btn
              icon
              color="primary"
              v-on="menu"
              class="grab-downloads-action-menu-btn"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="canDownload" @click="downloadItem(item)">
              <v-list-item-title class="body-2">
                <v-icon small>mdi-download</v-icon>
                {{ $t("common.actions.download") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import fetchClient from "@/utils/fetchClient"
import formatter from "@/utils/formatter"

import ParameterListView from "./ParameterListView.vue"

export default {
  props: ["machine"],
  components: {
    ParameterListView
  },
  data: () => ({
    files: [],
    loading: false
  }),
  created() {
    this.fetchFileList()
  },
  computed: {
    ...mapGetters(["canReadDownloads", "isAdmin", "isService", "isProduction"]),

    canDownload() {
      return this.canReadDownloads || this.isAdmin
    },
    showExpand() {
      return this.isProduction
    },
    headers() {
      let hdrs = []

      if (this.canDownload) {
        hdrs.push({
          text: "",
          value: "icon",
          hide: "xs",
          width: "56px",
          sortable: false
        })
      }

      hdrs.push({
        text: this.$t("reports.dataTable.headers.name"),
        value: "name"
      })
      hdrs.push({ text: this.$t("downloads.size"), value: "size" })
      hdrs.push({
        text: this.$t("reports.dataTable.headers.modified"),
        value: "modified"
      })

      if (this.canDownload) {
        hdrs.push({
          text: this.$t("common.dataTable.headers.actions"),
          value: "action",
          align: "center",
          sortable: false
        })
      }

      return hdrs.filter(h => !h.hide || !this.$vuetify.breakpoint[h.hide])
    },

    fileList() {
      return this.files.map(file => {
        return {
          icon: "mdi-archive-arrow-down-outline",
          key: file.key,
          name: file.name,
          size: `${Math.floor(file.size / 1024)} kb`,
          modified: this.formatDate(file.modified),
          downloading: false
        }
      })
    }
  },
  methods: {
    ...mapActions(["setError"]),

    async fetchFileList() {
      const url = `/api/v1/tenants/${this.machine.tenantUid}/machines/${this.machine.serialNo}/parameterFiles`
      this.loading = true

      try {
        const response = await fetchClient(this.$i18n).getDataOrThrow(url)
        this.files = response.files
      } catch (error) {
        this.setError(error)
      } finally {
        this.loading = false
      }
    },
    async downloadItem(file) {
      try {
        const url = `/api/v1/tenants/${this.machine.tenantUid}/machines/${this.machine.serialNo}/parameterFiles/${file.name}`
        const blob = await fetchClient(this.$i18n).getBlobOrThorw(url)

        let link = document.createElement("a")
        link.href = window.URL.createObjectURL(blob)
        link.download = file.name
        link.click()
      } catch (error) {
        this.setError(error)
      }
    },
    formatDate(date) {
      if (this.isProduction) {
        if (!(date instanceof Date)) {
          date = new Date(date)
        }
        return date.toISOString()
      }

      return formatter(this.$i18n).formatDate(date)
    }
  }
}
</script>

<style scoped></style>
