export default {
  machines: {
    actions: {
      addMachine: "Enregistrer un engin",
      clearProductFilters: null,
      importMachine: "Importer un engin",
      provisioned: "Provisionné",
      transfer: "Transférer",
      unprovisioned: "Non provisionné"
    },
    activities: {
      beltActiveTime: "Temps d'activité de la courroie",
      otherTime: "Autre temps",
      toolActiveTime: "Temps d'activité de l'outil"
    },
    alarms: {
      dialogs: {
        extraInfo: {
          dataTable: {
            headers: {
              category: "Catégorie",
              description: "Description",
              value: "Valeur"
            }
          }
        }
      },
      moreExists: "il y en a d’autres"
    },
    dataTable: {
      headers: {
        active: "Actif",
        brokkConnect: "Brokk Connect",
        category: "Catégorie",
        code: "Code",
        date: "Date",
        description: "Description",
        device: "Dispositif",
        engineHours: "Heures moteur",
        extraInfo: "Informations supplémentaires",
        id: "ID",
        lastSeen: "Vu pour la dernière fois",
        name: "Nom",
        number: "Numéro",
        product: "Produit",
        serviceAgreement: "Contrat d’entretien",
        serviceStatus: "Statut de l’entretien",
        severity: "Gravité",
        status: "Statut",
        tenantName: "Locataire",
        updateInterval: "Intervalle de mise à jour"
      },
      tooltips: {
        cellular: "Brokk Connect utilise le réseau cellulaire",
        overdue: "En retard",
        pending: "En attente",
        service: "Entretien",
        serviceAgreementMissing: "Contrat d’entretien absent",
        serviceAgreementPresent: "Contrat d’entretien présent",
        unknown: "Inconnu",
        unprovisioned: "Brokk Connect non installé",
        upcoming: "OK",
        wifi: "Brokk Connect utilise le réseau Wi-Fi"
      }
    },
    dialogs: {
      addMachine: {
        addMachineSucceededText: "L’engin Brokk {machine} a été ajouté.",
        confirmAddMachine: "Voulez-vous ajouter l’engin ?",
        confirmAddMachineProceedInfo:
          "Pour ajouter l’engin, sélectionnez un modèle et appuyez sur Suivant.",
        confirmAddMachineProceedInfoNext:
          "Pour ajouter l’engin, appuyez sur Suivant.",
        confirmAddMachineTitle: "Ajouter l’engin ?",
        engineHours: "Heures moteur",
        enterEngineHoursTitle: "Entrer les heures moteur",
        enterMachineInformationTitle: "Entrer les informations de l’engin",
        enterserialNoDescription: "Entrer un numéro de série valide",
        enterserialNoTitle: "Entrer le numéro de série de l’engin",
        errors: {
          couldNotAddMachine: "{msg}",
          couldNotRegisterService: "{msg}",
          couldNotUpdateService: "{msg}",
          noSuchserialNo: "Erreur : Le numéro de série n'existe pas"
        },
        serialNo: "Numéro de série",
        steps: {
          confirm: "Confirmer",
          overview: "Aperçu",
          summary: "Résumé"
        },
        title: "Ajouter un engin non provisionné"
      },
      addServiceSchedule: {
        comment: "Commentaire relatif à l'entretien",
        commentHint:
          "Le commentaire relatif à l'entretien sera visible dans l'historique des entretiens",
        commentPlaceholder: "Saisir un commentaire relatif à l’entretien",
        engineHours: "Heures moteur",
        enterEngineHoursTitle: "Saisir les heures de fonctionnement du moteur",
        service2: "Entretien 2",
        service3: "Entretien 3",
        service4: "Entretien 4",
        serviceInformationTitle:
          "Entrer les informations relatives à l’entretien",
        serviceTitle: "Entretien",
        steps: {
          confirm: "Confirmer",
          overview: "Aperçu",
          summary: "Résumé"
        },
        title: "Ajouter un tableau d'entretien"
      },
      commissioning: {
        saved: "La mise en service a été enregistrée avec succès.",
        steps: {
          applications: {
            hint: "(Facultatif) Sélectionner une ou plusieurs applications",
            name: "Applications"
          },
          category: {
            name: "Catégorie",
            otherCategoryHint: "Veuillez préciser une autre catégorie",
            otherCategoryPlaceholder: "Autre catégorie"
          },
          commissioning: {
            description:
              "Veuillez choisir la date de mise en service de l’engin. Cette date déterminera les dates de début et de fin de la garantie.",
            name: "Mise en service"
          },
          confirm: {
            description:
              "Veuillez confirmer que les informations suivantes sont correctes :",
            name: "Confirmer",
            proceedInfo:
              "Pour mettre l’engin en service, appuyez sur Enregistrer."
          },
          segment: {
            description:
              "Veuillez choisir un segment et une catégorie qui décrivent la manière dont l’engin sera utilisé.",
            name: "Segment"
          }
        },
        title: "Mise en service de l'engin"
      },
      editMachine: {
        createdTimestamp: "Créé",
        description: "Description",
        descriptionPlaceholder: "Entrez la description",
        name: "Nom",
        namePlaceholder: "Entrez le nom",
        revision: "Révision",
        serialNo: "Numéro de série",
        tenant: "Locataire",
        tenantHint:
          "Le locataire ne peut pas être modifié pendant qu'un transfert est en attente",
        tenantPlaceholder: "Sélectionnez le locataire",
        title: "Modifier l’engin"
      },
      editServiceSchedule: {
        engineHours: "Heures moteur",
        enterEngineHoursTitle: "Saisir les heures de fonctionnement du moteur",
        service2: "Entretien 2",
        service3: "Entretien 3",
        service4: "Entretien 4",
        serviceInformationTitle:
          "Entrer les informations relatives à l’entretien",
        serviceTitle: "Entretien",
        steps: {
          confirm: "Confirmer",
          overview: "Aperçu",
          summary: "Résumé"
        },
        title: "Modifier un tableau d'entretien"
      },
      editSettings: {
        cantEdit: "Ce paramètre ne peut pas être modifié",
        category: "Catégorie",
        description: "Description",
        descriptionPlaceholder: "Entrez la description",
        device: "Dispositif",
        id: "ID",
        number: "Numéro",
        operatorMustConfirm:
          "Vos modifications nécessiteront l'approbation d'un opérateur avant de prendre effet",
        title: "Modifier l'intervalle de mise à jour",
        updateInterval: "Intervalle de mise à jour"
      },
      importMachine: {
        confirmTransferMachine:
          "Souhaitez-vous transférer la {machine} avec numéro de série {sn} au locataire actuel {tenant} ?",
        confirmTransferProceedInfo:
          "Pour transférer l’engin à ce locataire, appuyez sur Suivant.",
        confirmTransferTitle: "Transférer l’engin ?",
        enterTransferCodeDescription:
          "Entrez un code de transfert pour déplacer un engin d'un autre locataire vers le locataire actuellement sélectionné",
        enterTransferCodeTitle: "Entrez le code de transfert",
        errors: {
          couldNotCompleteTransfer:
            "Erreur : Impossible de terminer le transfert. {msg}",
          noSuchTransferCode: "Erreur : Pas de tel code de transfert",
          transferCodeAlreadyUsed:
            "Erreur : Le code de transfert a déjà été utilisé",
          transferCodeExpired: "Erreur : Le code de transfert a expiré",
          transferReverted: "Erreur : Le transfert a été annulé"
        },
        steps: {
          confirm: "Confirmer",
          overview: "Aperçu",
          summary: "Sommaire"
        },
        title: "Transférer l’engin à {tenant}",
        transferCode: "Code de transfert",
        transferCodePlaceholder: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
        transferSucceededText:
          "La {machine} avec le numéro de série {sn} a été transférée à {tenant}."
      },
      transferMachine: {
        errors: {
          internalError:
            "Aucun code de transfert n'a pu être généré pour le moment en raison d'une erreur interne.",
          machineAlreadyTransferred:
            "Cet engin a été transféré à un autre locataire",
          pendingTransfer: "Cet engin a un transfert en attente qui a été créé",
          transferCodeExpires: "Le code de transfert expire"
        },
        refreshTheList: "Veuillez actualiser la liste des engins.",
        steps: {
          confirm: {
            description:
              "Transférer la propriété de l’engin {sn} ({productName}) actuellement détenu par {tenant} ?",
            includeHistoricalDataInfo:
              "Le transfert inclura des données historiques.",
            name: "Confirmer",
            notIncludeHistoricalDataInfo:
              "Le transfert n'inclura pas les données historiques.",
            proceedInfo:
              "Pour générer un code de transfert et initier le transfert appuyez sur Suivant.",
            title: "Confirmer"
          },
          dataRetention: {
            description:
              "En incluant les données historiques de l’engin dans le transfert, vous permettez au destinataire de visualiser toutes les alarmes, événements et mesures historiques, y compris la géolocalisation.",
            includeHistoricalData:
              "Inclure les données historiques dans le transfert.",
            name: "Conservation des données",
            title: "Inclure l'historique"
          },
          summary: {
            description:
              "Copiez le code de transfert ci-dessous et envoyez-le par e-mail au destinataire, qui l'utilisera pour terminer le transfert.",
            name: "Sommaire",
            title: "Code de transfert"
          },
          transferOwnership: {
            description:
              "Transférer la propriété de cet engin à un autre locataire à l'aide d'un code de transfert. L’engin restera accessible tant que le transfert sera en attente.",
            name: "Aperçu",
            title: "Transférer la propriété"
          }
        },
        title: "Transférer l’engin",
        transferAutomaticRevertInfo:
          "Le transfert sera automatiquement annulé si le destinataire ne termine pas le transfert dans les 3 jours.",
        transferCode: "Code de transfert"
      }
    },
    dieselParticulateFilter: {
      info: {
        error: "Contactez Brokk Service.",
        ok: "Statut OK.",
        title: "Filtre à particules diesel",
        warning:
          "Une régénération manuelle est requise. Voir l'affichage sur l’engin."
      },
      status: {
        ashLevel: "Niveau de cendres",
        sootLevel: "Niveau de suie",
        title: "Statut du FAP"
      }
    },
    engineHoursSelectionList: {
      lastDay: "Le dernier jour",
      lastMonth: "Le mois dernier",
      lastWeek: "La semaine dernière",
      total: "Total"
    },
    featureNotAvailable:
      "Cette fonction n'est actuellement pas activée sur cet engin",
    filters: {
      dataSource: "Source des données"
    },
    info: {
      order: {
        commissioningDate: "Date de mise en service",
        commissioningHint: "6 mois max. à partir de la date de livraison",
        deliveryDate: "Date de livraison",
        label: "Commande d'engins",
        orderNumber: "Numéro de commande",
        orderType: "Type de commande"
      },
      segment: {
        actions: {
          commission: "Mettre en service cet engin"
        },
        applications: "Applications",
        category: "Catégorie",
        label: "Segment",
        overlay: {
          description:
            "Mettez l'engin en service pour activer sa garantie et définissez le type d'applications dans lesquelles il sera utilisé.",
          disabledDescription:
            "La mise en service sera possible une fois que la date de livraison et la durée de la garantie auront été déterminées pour l'engin.",
          heading: "Cet engin est en stock"
        }
      },
      warranty: {
        endDate: "Date de fin",
        label: "Garantie",
        length: "Durée",
        startDate: "Date de début"
      }
    },
    labels: {
      activeAlarms: "Alarmes actives",
      elMotorTemp: "El température du moteur",
      engineHours: "Heures moteur",
      engineHoursDays: "Moteur Heures / jour",
      fuelLevel: "Niveau de carburant",
      highPressureTime: "Durée haute pression",
      highTempTime: "Durée haute température",
      hydraulicFluidTemp: "Temp. liquide hydraulique",
      machineActivity: "Activité de l’engin",
      nextServiceIn: "Prochain entretien dans",
      noMachines: {
        descriptionHasAccess:
          "Appuyez sur le bouton ci-dessous pour enregistrer un engin non provisionné. Vous aurez ainsi immédiatement accès à la documentation et aux pièces de rechange correspondantes.",
        descriptionNoAccess:
          "En enregistrant votre engin, vous accédez immédiatement à la documentation et aux pièces de rechange correspondantes. Contactez votre revendeur le plus proche pour enregistrer votre engin.",
        title: "Enregistrer un engin"
      },
      notification: "Notification"
    },
    location: {
      currentMachineSettings: "Paramètres actuels de la machine",
      dialogs: {
        extraInfo: {
          dataTable: {
            headers: {
              propertyCategory: "Catégorie",
              propertyName: "Nom",
              propertyValue: "Valeur"
            }
          }
        }
      },
      events: {
        dataTable: {
          headers: {
            date: "Date",
            description: "Description",
            extraData: "Informations supplémentaires",
            source: "Source"
          }
        }
      },
      geofencingSettings: {
        actions: {
          add: "Ajouter une clôture géographique",
          edit: "Modifier les paramètres du périmètre virtuel",
          remove: "Supprimer les paramètres du périmètre virtuel"
        },
        confirm: {
          description:
            " Voulez-vous vraiment appliquer les modifications apportées au périmètre virtuel ? Cela peut affecter la capacité de fonctionnement de l’engin. Veuillez vous assurer que l’engin est dans une position qui ne causera aucun dommage ni préjudice aux personnes ou au matériel.",
          title: "Appliquer le périmètre virtuel"
        },
        coordinates: "Coordonnées",
        description: " ",
        latitude: "Latitude",
        latitudePlaceholder: "Entrez la latitude",
        limit: {
          description: "",
          limitation: "Limitation",
          limitationPlaceholder: "Sélectionnez la limitation à ajouter",
          title: "Limites"
        },
        longitude: "Longitude",
        longitudePlaceholder: "Entrez la longitude",
        messages: {
          anyLimitationOrNotificationRequired:
            "Veuillez spécifier une ou plusieurs notifications",
          disabled:
            "La fonction de périmètre virtuel n'est pas activée sur cette machine.",
          noLimitationsAdded: "Aucune limitation ajoutée pour le moment.",
          settingsNotSynched:
            "Les paramètres du périmètre virtuel n'ont pas été synchronisés par la machine.",
          settingsRemoved: "Le périmètre virtuel a été supprimé",
          settingsSaved: "Le périmètre virtuel a été enregistré"
        },
        notifications: {
          description:
            "Saisissez les adresses e-mail des personnes qui doivent recevoir des notifications par e-mail lorsque la machine entre ou sort de la zone du périmètre virtuel.",
          title: "Notifications"
        },
        radius: "Rayon",
        radiusPlaceholder: "Entrez le rayon",
        title: "Périmètre virtuel",
        triggers: {
          enter: "Entrer",
          exit: "Sortir"
        },
        unit: "Unité",
        unitPlaceholder: "Entrez l'unité"
      },
      geolocationSettings: {
        actions: {
          edit: "Modifier les paramètres de géolocalisation",
          remove: "Supprimer les paramètres de géolocalisation"
        },
        description: "Paramètres de suivi de géolocalisation hors ligne",
        distance: "Distance",
        distanceHint:
          "Distance minimale que la machine doit parcourir avant d'envoyer une mise à jour",
        distancePlaceholder: "Entrer la distance",
        interval: "Intervalle",
        intervalHint:
          "Intervalle de temps minimum qui doit s'écouler entre les mises à jour",
        intervalPlaceholder: "Sélectionnez l'intervalle",
        messages: {
          disabled:
            "La fonctionnalité de géolocalisation n’est actuellement pas activée sur cette machine.",
          settingsNotSynched:
            "Les paramètres de géolocalisation n’ont pas été synchronisés par la machine.",
          settingsRemoved:
            "Les paramètres de géolocalisation ont été supprimés",
          settingsSaved: "Les paramètres de géolocalisation ont été enregistrés"
        },
        movement: "Mouvement",
        timer: "Minuteur",
        title: "Paramètres de géolocalisation",
        unit: "Unité",
        unitPlaceholder: "Sélectionnez l'unité"
      },
      location: {
        actions: {
          setGeofencingLocation:
            "Définissez l'emplacement du périmètre virtuel ici"
        },
        description:
          "Définissez une zone où la machine doit fonctionner et recevez des notifications lorsqu'elle entre ou sort de la zone.",
        messages: {
          changeGeofencingCoordinatesTip:
            "Utilisez le bouton « Tracer un cercle » sur la carte pour définir ou modifier le périmètre virtuel de la machine."
        },
        showHistory: "Afficher l'historique des positions",
        title: "Paramètres de localisation"
      },
      map: {
        geofenceCenter: "Centre de périmètre virtuel",
        machineLocation: "Emplacement de la machine"
      }
    },
    maintenance: {
      service: {
        add: "L'entretien a été ajouté avec succès",
        beforeNextServiceYearlyService:
          "Avant le prochain entretien / entretien annuel",
        commentMaxChars:
          "Le commentaire doit comporter 2000 caractères au maximum",
        overdue: "Services en retard",
        reset: {
          actions: {
            send: "Envoyer la commande de réinitialisation du service"
          },
          availableForServiceUsersOnly:
            "La fonction de réinitialisation du service n'est disponible que pour les utilisateurs du service.",
          comment: "Commentaire de service",
          commentHint:
            "Le commentaire de service sera visible dans l'historique de service",
          commentPlaceholder: "Saisir un commentaire sur le service",
          confirm: {
            description:
              "Êtes-vous sûr de vouloir effectuer une réinitialisation du service sur l’engin <strong>{0}</strong> ?",
            note: "Remarque ! Cette action ne peut pas être annulée.",
            title: "Confirmer la réinitialisation du service"
          },
          description:
            "Ceci enverra une commande de réinitialisation de service à l’engin et fonctionnera comme une réinitialisation de service effectuée à l'aide de Brokk Config Tool.",
          disabled:
            "La fonction de réinitialisation du service n'est actuellement pas activée sur cet engin.",
          errors: {
            notAvailable:
              "Une réinitialisation de service peut être effectuée 100 heures avant l’entretien programmé ou lorsqu'un entretien annuel est en attente.",
            offline:
              "L’engin doit être en ligne pour qu'un service soit réinitialisé.",
            waitingForYearly:
              "L’entretien annuel n'a pas encore été activé sur l’engin. Cela peut prendre une heure avant qu'il ne devienne actif. Veuillez patienter et réessayer plus tard."
          },
          success: {
            description:
              "Le service sur l’engin <strong>{0}</strong> a été réinitialisé avec succès !",
            title: "Réinitialisation du service réussie"
          },
          title: "Réinitialisation du service"
        },
        schedule: {
          dataTable: {
            headers: {
              comment: "Commentaire",
              loggedHours: "Heures enregistrées",
              scheduled: "Programmé",
              service: "Entretien",
              serviceDate: "Date d'entretien",
              status: "Statut"
            }
          },
          description:
            "Le tableau d’entretien est basé sur la durée de fonctionnement et sur la date du dernier entretien de l'engin. Les conditions de travail pouvant varier, il peut être nécessaire d'adapter l'intervalle d'entretien aux conditions et à l’environnement de travail concernés.",
          received: "Reçu",
          title: "Tableau d'entretien"
        },
        update: "L'entretien a été mis à jour avec succès"
      },
      serviceAgreement: {
        actions: {
          add: "Ajouter un nouveau contrat d’entretien",
          edit: "Modifier le contrat d’entretien"
        },
        labels: {
          description: "Description",
          endDate: "Date de fin",
          filename: "Nom de fichier",
          fileuploadLabel: "Document du contrat d'entretien",
          startDate: "Date de début",
          uploadFilePlaceholder:
            "Télécharger le document contenant le contrat d'entretien précisé"
        },
        messages: {
          added: "Un nouveau contrat d'entretien a été ajouté",
          deleted: "Le contrat d'entretien a été supprimé",
          failedToDownload:
            "Échec du téléchargement du document du contrat d'entretien {file}",
          updated: "Le contrat d'entretien a été mis à jour"
        },
        title: "Contrat d’entretien",
        warnings: {
          isExpired: "Le contrat d'entretien est arrivé à échéance"
        }
      }
    },
    manuals: {
      actions: {
        downloadFile: "Télécharger un fichier"
      },
      dataTable: {
        headers: {
          fileName: "Nom de fichier"
        }
      },
      messages: {
        failedToDownload: "Échec du téléchargement {file}"
      }
    },
    measurements: {
      electricMotor: "Moteur électrique",
      electronicUnit: "Unité électronique",
      engineHours: "Heures moteur",
      export: {
        csv: {
          columns: {
            category: "Catégorie",
            description: "Description",
            parameterId: "Identifiant du paramètre",
            timestamp: "Horodatage",
            unit: "Unité",
            value: "Valeur"
          }
        }
      },
      gateway: "Passerelle",
      highPressureTime: "Durée haute pression",
      highTempTime: "Durée à haute température",
      hydraulicFluid: "Liquide hydraulique",
      hydraulicFluidPressure: "Pression de fluide hydraulique",
      temperatures: "Températures"
    },
    menuItems: {
      alarms: "Alarmes",
      dashboard: "Tableau de bord",
      info: "Info",
      location: "Emplacement",
      maintenance: "Entretien",
      manuals: "Manuels",
      measurements: "Mesures",
      parameters: "Paramètres",
      rental: "Location",
      system: "Système"
    },
    messages: {
      applyChangesPending:
        "En attente de l'application des modifications par l'appareil",
      changesApplied: "Les modifications ont été appliquées",
      contactToLearnMore: "Pour en savoir plus, veuillez contacter",
      errorApplyingChanges: "Erreur lors de l'application des modifications",
      fetchingMachineDetails: "Récupération des détails de l’engin ...",
      machineAdded: "L’engin a été ajouté",
      machineDetailsAreBeeingFetched:
        "Les détails de l’engin sont récupérés. Veuillez patienter.",
      machineDoesNotLoadInfo:
        " Si l’engin ne se charge pas, veuillez revenir en arrière et actualiser manuellement la vue des engins.",
      machineOffline: "La machine est hors ligne.",
      machineUpdated: "L’engin a été mis à jour",
      unsavedChanges: "Modifications non enregistrées"
    },
    missingMachineImage: "Image du produit manquante",
    notFound: "Engin introuvable",
    notifications: {
      settings: {
        actions: {
          edit: "Modifier les paramètres de notification",
          remove: "Supprimer les paramètres de notification",
          send: "Envoyer une notification"
        },
        daysBeforeLeaseExpiration:
          "Nombre de jours avant expiration de la location",
        daysBeforeNextYearlyService: "Jours avant le prochain entretien annuel",
        description:
          "Saisissez les adresses e-mail des personnes qui devraient recevoir des notifications par e-mail.",
        dialogs: {
          sendNotification: {
            confirm:
              "Voulez-vous envoyer une notification par e-mail au {count} destinataire ? | Souhaitez-vous envoyer une notification par e-mail aux {count} destinataires ?",
            successMessage:
              "La notification par e-mail a été envoyée | Des notifications par e-mail ont été envoyées"
          }
        },
        engineHoursBeforeLeaseExpires:
          "Heures de moteur avant l'expiration du bail",
        engineHoursBeforeNextService:
          "Heures moteur avant la prochaine révision",
        maxRecipientsHint: "Au maximum 5 adresses e-mail peuvent être ajoutées",
        messages: {
          notificationSettingsRemoved:
            "Les paramètres de notification ont été supprimés",
          notificationSettingsSaved:
            "Les paramètres de notification ont été supprimés"
        },
        noLeaseAdded:
          "Pour ajouter des notifications par e-mail, veuillez revenir à l’onglet des locations et préciser une location.",
        norecipientsAdded: "Aucun destinataire n'a encore été ajouté.",
        recipients: "Destinataires",
        selectEmails:
          "Sélectionnez jusqu'à 5 adresses e-mail auxquelles les notifications seront envoyées",
        sendHint: "Envoyer un e-mail à tous les destinataires maintenant",
        threshold: "Seuil",
        title: "Paramètres de notification"
      }
    },
    parameters: {
      dataTable: {
        headers: {
          category: "Catégorie",
          description: "Description",
          device: "Dispositif",
          id: "ID",
          number: "Numéro",
          value: "Valeur"
        }
      },
      dialogs: {
        editParameter: {
          category: "Catégorie",
          messages: {
            cantBeEdited: "Ce paramètre ne peut pas être modifié",
            changesNeedApproval:
              "Vos modifications nécessiteront l'approbation d'un opérateur avant de prendre effet"
          },
          parameterNo: "Numéro du paramètre",
          updateinterval: "Intervalle de mise à jour"
        }
      }
    },
    props: {
      description: "Description",
      lastSeen: "Vu pour la dernière fois",
      name: "Nom",
      product: "Produit",
      serialNo: "Numéro de série",
      serviceStatus: "Statut de l’entretien",
      status: "Statut",
      tenant: "Locataire"
    },
    rental: {
      lease: {
        notDefined: {
          description:
            "Pour ajouter une location, veuillez appuyer sur le bouton ci-dessous.<br/>Vous serez ensuite guidé dans les différentes étapes pour utiliser la fonction de location sur votre engin.",
          title: "Aucune location n'a été définie"
        },
        notEnabled: {
          description:
            "La location d’engin peut être achetée en complément.<br/>Pour en savoir plus, veuillez contacter",
          title:
            "La fonction de location n'est pas activée actuellement sur cet engin."
        },
        notSupportingDate:
          "Cet engin ne prend en charge que la location par Heure moteur",
        settings: {
          actions: {
            add: "Ajouter des paramètres de location",
            edit: "Modifier les paramètres du bail",
            remove: "Supprimer les paramètres du bail"
          },
          configuredLeaseSettings: "Paramètres de bail configurés.",
          confirm: {
            description:
              " Êtes-vous sûr de vouloir appliquer le bail? Cela peut affecter la capacité de fonctionnement de l’engin. Veuillez vous assurer que l’engin est dans une position qui ne causera aucun dommage ni préjudice aux personnes ou au matériel.",
            title: "Confirmer la mise à jour du bail"
          },
          description:
            " Imposer des restrictions sur l’engin après que les heures moteur spécifiées ont été atteintes",
          engineHours: "Heures moteur",
          engineHoursHover:
            "Les dernières heures moteur connues signalées par l’engin",
          headers: {
            active: "Actif",
            requested: "Demandé"
          },
          leaseEnd: "Fin de la location",
          leaseSettingsReportedByMachine:
            "Paramètres du bail signalés par l’engin.",
          leaseStatusDescription: {
            applied: "Les modifications ont été appliquées",
            notApplicable: "N / A",
            pending: "En attente de l'appareil"
          },
          machineRestrictions: "Restrictions relatives à l'engin",
          messages: {
            emailNotificationHasBeenSent:
              "La notification par e-mail a été envoyée",
            leaseUpdateRequestHasBeenSent:
              "Une demande de mise à jour du bail a été envoyée à l’engin. Une notification sera envoyée lorsqu'elle aura été appliquée."
          },
          restrictionTypes: {
            blinkLights: "Clignotement des lumières",
            disableToolA: "Désactiver l'outil A",
            reduceMovementSpeedToLvl3:
              "Réduire la vitesse de déplacement au Niveau de réduction de vitesse 3",
            stopEngineAfter: "Arrêter le moteur après"
          },
          sendEmailNotificationAboutLeaseChange:
            "Voulez-vous envoyer une notification par e-mail maintenant pour informer l'opérateur que les paramètres du bail sont sur le point de changer ?",
          title: "Paramètres du bail"
        }
      }
    },
    service: "Entretien",
    system: {
      newVersionAvailable:
        "Une nouvelle version est disponible en téléchargement.",
      newVersionDetails: "Version {version}, publiée le {releaseDate}.",
      noDataDescription:
        "Ceci est normal lorsque l’engin est neuf et n'a pas encore communiqué sesvaleurs. Veuillez vérifier à nouveau après quelques heures de fonctionnement du moteur."
    },
    tabHeaders: {
      alarms: "Alarmes",
      events: "Événements",
      lease: "Bail",
      location: "Emplacement",
      measurements: "Mesures",
      notifications: "Notifications",
      service: "Entretien",
      serviceAgreement: "Contrat d’entretien",
      settings: "Réglages"
    },
    updated: "Mis à jour {TIME}"
  }
}
