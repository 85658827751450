export default {
  tenant: {
    contacts: {
      actions: {
        addContact: "Ajouter des coordonnées",
        editContact: "Modifier les coordonnées",
        removeContact: "Supprimer les coordonnées"
      },
      contactTypes: {
        sales: "Ventes",
        service: "Entretien"
      },
      dataTable: {
        headers: {
          address: "Adresse",
          name: "Nom",
          phoneNumber: "Numéro de téléphone",
          type: "Type"
        }
      },
      dialogs: {
        edit: {
          title: "Modifier le contact"
        }
      },
      messages: {
        contactAdded: "Un nouveau contact a été ajouté",
        contactUpdated: "Le contact a été mis à jour",
        removed: "Le contact a été supprimé"
      },
      title: "Contacts"
    },
    customer: {
      actions: {
        addCustomer: "Ajouter des informations relatives au client",
        addDeliveryAddress: "Ajouter une adresse de livraison",
        editCustomer: "Modifier les informations relatives au client",
        editDeliveryAddress: "Modifier l’adresse de livraison",
        removeCustomer: "Supprimer les informations relatives au client"
      },
      labels: {
        addDetailsQuestion:
          "Voulez-vous ajouter les informations relatives au client pour ce locataire ? Ceci permettra de créer un numéro de client pour la boutique en ligne.",
        address: "Adresse",
        addressDialogDescription:
          "Entrez les informations relatives à l'adresse de livraison du client.",
        addressLine: "Ligne d’adresse",
        customerNo: "Numéro de client",
        customerType: "Type de client",
        dealer: "Revendeur",
        dealerAddressDescription:
          "L'adresse du revendeur sera récupérée de Monitor par la boutique en ligne",
        deliveryAddressDescription:
          "Adresses auxquelles les commandes effectuées sur la boutique en ligne peuvent être expédiées. Il est possible d'ajouter {maxRecords} adresses au maximum.",
        deliveryAddressInfo:
          "Il n'est pas possible d'ajouter d'autres adresses de livraison. Le nombre maximum d'adresses est atteint.",
        deliveryAddresses: "Adresses de livraison",
        discount: "Pourcentage de remise de la boutique en ligne",
        endCustomer: "Client final",
        endCustomerName: "Nom du client final",
        endCustomerNo: "Numéro du client final",
        freightContact: "Contact pour le fret",
        missingCountry:
          "Il est nécessaire de préciser un pays avant de pouvoir ajouter les informations relatives au client. Veuillez en sélectionner un dans la vue Profil du locataire.",
        priceListId: "ID de la liste de prix de la boutique en ligne"
      },
      messages: {
        removed: "Les informations relatives au client ont été supprimées",
        saved: "Les informations relatives au client ont été enregistrées"
      },
      placeholders: {
        address: "Entrer l'adresse",
        customerNo: "Entrer un numéro de client Monitor",
        customerType: "Sélectionner le type de client",
        discount: "Entrer la remise de la boutique en ligne (0-100 %)",
        freightContact: "Entrer le contact pour le fret",
        priceListId: "Entrer l’ID de la liste de prix de la boutique en ligne"
      },
      title: "Client"
    },
    dataTable: {
      headers: {
        city: "Ville",
        country: "Pays",
        description: "Description",
        name: "Nom"
      }
    },
    dialogs: {
      edit: {
        addTitle: "Ajouter un locataire",
        city: "Ville",
        cityPlaceholder: "Entrez le nom de la ville",
        country: "Pays",
        countryPlaceholder: "Choisissez le pays",
        description: "Description",
        descriptionPlaceholder: "Entrez la description",
        editTitle: "Modifier le locataire",
        engineHoursBeforeNextService:
          "Heures moteur avant la prochaine révision",
        enterEmailAddress: "Entrez l'adresse e-mail",
        messages: {
          added: "Le locataire a été ajouté",
          parentChanged: "Le parent du locataire a été modifié",
          updated: "Le locataire a été mis à jour"
        },
        name: "Nom",
        namePlaceholder: "Entrez le nom du locataire",
        sendMaintanenceNotifications:
          "Envoyez des notifications par e-mail relatives à la maintenance."
      }
    },
    itemName: "Locataire",
    menuItems: {
      downloads: "Téléchargements",
      fleetAnalytics: "Fleet Analytics",
      fleet_analytics: "Tableau de bord Fleet Analytics",
      machines: "Engins",
      manuals: "Manuels",
      manualsProductId: "Manuels pour Brokk ",
      map: "Carte",
      reports: "Rapports",
      tenants: "Locataires",
      users: "Utilisateurs"
    },
    noLocationData: "Aucune donnée de localisation à afficher",
    profile: {
      title: "Profil du locataire"
    },
    selectAnObject: "Veuillez sélectionner un objet dans l'arborescence."
  }
}
