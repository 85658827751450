<template>
  <v-card>
    <v-card-title>Inspection</v-card-title>
    <v-card-subtitle>
      <template>
        <v-row dense>
          <v-col cols="12" sm="10"
            >Items to check during machine inspection</v-col
          >
          <v-col cols="12" sm="2" class="d-flex justify-end">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  @click="onRefresh"
                  :loading="isLoading"
                  :disabled="isLoading"
                >
                  <v-icon>mdi-cached</v-icon>
                  <template v-slot:loader>
                    <span class="refreshLoader">
                      <v-icon>mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn>
              </template>
              <span>{{ $t("common.actions.refresh") }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
    </v-card-subtitle>
    <v-card-text>
      <v-alert
        v-model="showError"
        closable
        icon="$error"
        title="Error"
        type="error"
      >
        <div>{{ errorText }}</div>
      </v-alert>
      <v-progress-linear
        indeterminate
        color="yellow darken-2"
        :active="isLoading"
      ></v-progress-linear>

      <InspectionTable
        title="Items that are checked automatically"
        :items="autoItems"
      />

      <InspectionTable
        title="Items that require manual inspection"
        :items="manualItems"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import fetchClient from "@/utils/fetchClient"

import InspectionTable from "../../components/machines/InspectionTable.vue"

export default {
  props: ["tenantUid", "serialNo"] /* passed by the Vue Router */,
  components: {
    InspectionTable
  },
  data: () => ({
    isLoading: false,
    showError: false,
    errorText: null,
    items: []
    //   {
    //     status: "success",
    //     item: "Parameters",
    //     description: "Factory tuned parameters saved",
    //     value: "B170-993232-factory-tuned.csv",
    //     lastUpdated: "2024-10-12 12:13:14"
    //   },
    //   {
    //     status: "warning",
    //     item: "GPS Position",
    //     description: "GPS position not updated or out of bounds",
    //     value: null,
    //     lastUpdated: null
    //   },
    //   {
    //     status: "success",
    //     item: "GPS Position",
    //     description: "GPS position has been received",
    //     value: "Latitude: 12.34, Longitude: 24.34",
    //     lastUpdated: "2024-10-12 12:13:14"
    //   },
    //   {
    //     status: "info",
    //     item: "Disable service reset",
    //     description:
    //       "Configuration for disabling service reset option in the control unit",
    //     value: "1 (ON)",
    //     lastUpdated: "2024-10-12 12:13:14"
    //   }
    // ]
  }),
  async created() {
    await this.fetchInspectionStatus()
  },
  computed: {
    autoItems() {
      const filtered = this.items.filter(v => v.type === "auto")
      if (filtered.length > 0) {
        return filtered[0].items
      }
      return []
    },
    manualItems() {
      const filtered = this.items.filter(v => v.type === "manual")
      if (filtered.length > 0) {
        return filtered[0].items
      }
      return []
    }
  },
  methods: {
    async fetchInspectionStatus() {
      this.clearError()

      if (!this.serialNo) {
        return
      }

      try {
        this.isLoading = true
        const url = `/api/v1/machines/manufacturing/${this.serialNo}/inspection`
        this.items = await fetchClient(this.$i18n).getDataOrThrow(url)
      } catch (err) {
        this.showErrorMessage(
          `failed to fetch inspection items: ${err.message}`
        )
      } finally {
        this.isLoading = false
      }
    },
    async onRefresh() {
      await this.fetchInspectionStatus()
    },
    clearError() {
      this.showError = false
      this.errorText = ""
    },
    showErrorMessage(msg) {
      this.showError = true
      this.errorText = msg
    }
  }
}
</script>

<style lang="scss" scoped></style>
