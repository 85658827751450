<template>
  <v-container class="px-0">
    <p class="subtitle-1 text--primary mb-0">{{ title }}</p>
    <v-simple-table>
      <thead>
        <tr>
          <th width="5%">Status</th>
          <th width="15%">Item</th>
          <th width="30%">Description</th>
          <th width="25%">Value</th>
          <th width="auto">Timestamp</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td v-if="item.status === 'success'">
            <v-icon color="green">mdi-check-circle</v-icon>
          </td>
          <td v-else-if="item.status === 'info'">
            <v-icon color="primary">mdi-information</v-icon>
          </td>
          <td v-else-if="item.status === 'error'">
            <v-icon color="red">mdi-alert-circle</v-icon>
          </td>
          <td v-else><v-icon>mdi-help-circle</v-icon></td>
          <td>{{ item.item }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.value }}</td>
          <td>{{ item.lastUpdated }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-container>
</template>

<script>
export default {
  props: ["title", "items"],
  data: () => ({}),
  computed: {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
