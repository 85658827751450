<template>
  <div class="text-center d-inline-flex fluid">
    <v-menu v-model="menu" :close-on-content-click="false" offset-y bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn small text v-bind="attrs" v-on="on" :class="{ active: menu }">
          {{ filterName }}
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list class="main-filter-list" expand>
          <template v-for="group in items">
            <!-- 1itr is workaround for 'templates cannot be keyed' -->
            <v-list-group
              :v-model="group.active"
              :value="expandItems"
              :key="'1itr' + group.id"
            >
              <!-- Group -->
              <template v-slot:activator>
                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox
                      v-model="group.selected"
                      @click.stop="onGroupSelected(group)"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ group.label }} ({{ group.count }})
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <!-- Single product -->
              <v-list-item
                v-for="child in group.items"
                :key="child.id"
                :disabled="child.disabled"
              >
                <v-list-item-action>
                  <v-checkbox
                    v-model="child.selected"
                    @click="onChildSelected(group)"
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-title>
                  {{ child.label }} ({{ child.count }})
                </v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-divider
              vertical
              class="divider-vertical"
              :key="'2itr' + group.id"
            ></v-divider>
            <v-divider
              class="divider-horizontal"
              :key="'3itr' + group.id"
            ></v-divider>
          </template>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="onApply">
            {{ $t("common.actions.ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: ["filterName", "availableItems", "selectedItems"],
  data: () => ({
    menu: false,
    items: []
  }),
  created() {
    this.setSelection()
  },
  computed: {
    expandItems() {
      return this.$vuetify.breakpoint.lgAndUp
    }
  },
  methods: {
    onApply() {
      // Filters refreshed after every checkbox click, so there is no need to refresh them here
      this.menu = false
    },
    setSelection() {
      this.items = this.availableItems
      const flattened = this.items.map(group => group.items).flat()

      // single product
      for (let ix = 0; ix < flattened.length; ix++) {
        const selItem = this.selectedItems.find(si => {
          return si.id === flattened[ix].id
        })
        if (selItem) {
          flattened[ix].selected = true
        } else {
          flattened[ix].selected = false
        }
      }

      // groups
      const selectedGroups = this.selectedItems.filter(i => i.items)
      for (let group of this.items) {
        const selectedGroup = selectedGroups.find(
          selectedGroup => selectedGroup.id == group.id
        )
        if (selectedGroup) {
          group.selected = true
          for (let child of group.items) {
            child.selected = true
            child.disabled = true
          }
        } else {
          group.selected = false
          for (let child of group.items) {
            child.disabled = false
          }
        }

        group.highlighted =
          group.selected ||
          group.count == group.items.filter(child => child.selected).length
      }

      this.$forceUpdate()
    },
    onGroupSelected(group) {
      group.highlighted = group.selected
      for (let child of group.items) {
        child.selected = group.selected
        child.disabled = group.selected
      }

      this.refreshFilters()
      this.$forceUpdate()
    },
    onChildSelected(group) {
      group.highlighted =
        group.selected ||
        group.count == group.items.filter(child => child.selected).length

      this.refreshFilters()
      this.$forceUpdate()
    },
    refreshFilters() {
      const selected = this.items.flatMap(group =>
        group.selected ? group : group.items.filter(it => it.selected)
      )
      this.$emit("update:selectedItems", selected)
    }
  },
  watch: {
    menu(newMenuState) {
      if (newMenuState) {
        this.setSelection()
      }
    }
  }
}
</script>

<style>
.main-filter-list {
  display: flex;
  /* flex-wrap: wrap;  */
  * .v-list-item__action {
    margin-right: 1rem !important;
  }
  * .v-list-group__header {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  * .v-list-group__header__append-icon {
    margin: 0 !important;
    min-width: auto !important;
  }
  .v-list-group {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  /* 1248px = material lg - 16px scroll */
  @media (width < 1248px) {
    flex-direction: column;
  }
}
.divider-horizontal {
  @media (width >= 1248px) {
    display: none;
  }
}
.divider-vertical {
  @media (width < 1248px) {
    display: none;
  }
}
.v-btn.active .v-icon {
  transform: rotate(-180deg);
}
</style>
