export default {
  tenant: {
    contacts: {
      actions: {
        addContact: "Añadir datos de contacto",
        editContact: "Editar datos de contacto",
        removeContact: "Eliminar datos de contacto"
      },
      contactTypes: {
        sales: "Ventas",
        service: "Mantenimiento"
      },
      dataTable: {
        headers: {
          address: "Dirección",
          name: "Nombre",
          phoneNumber: "Número de teléfono",
          type: "Tipo"
        }
      },
      dialogs: {
        edit: {
          title: "Editar contacto"
        }
      },
      messages: {
        contactAdded: "Se ha añadido un nuevo contacto",
        contactUpdated: "El contacto se ha actualizado",
        removed: "Se ha eliminado el contacto"
      },
      title: "Contactos"
    },
    customer: {
      actions: {
        addCustomer: "Añadir detalles del cliente",
        addDeliveryAddress: "Añadir dirección de entrega",
        editCustomer: "Editar detalles del cliente",
        editDeliveryAddress: "Editar dirección de entrega",
        removeCustomer: "Eliminar detalles del cliente"
      },
      labels: {
        addDetailsQuestion:
          "¿Desea añadir detalles de cliente para este inquilino? Esto creará un número de cliente para la tienda web.",
        address: "Dirección",
        addressDialogDescription:
          "Introduzca los detalles de la dirección de entrega del cliente.",
        addressLine: "Línea de dirección",
        customerNo: "Número de cliente",
        customerType: "Tipo de cliente",
        dealer: "Distribuidor",
        dealerAddressDescription:
          "La dirección del distribuidor se obtendrá de Monitor por la tienda web",
        deliveryAddressDescription:
          "Direcciones a las que se pueden enviar los pedidos de la tienda web. Se puede añadir un máximo de {maxRecords} direcciones.",
        deliveryAddressInfo:
          "No se pueden añadir más direcciones de entrega. Se ha introducido el número máximo de direcciones.",
        deliveryAddresses: "Direcciones de entrega",
        discount: "Porcentaje de descuento en la tienda web",
        endCustomer: "Cliente final",
        endCustomerName: "Nombre del cliente final",
        endCustomerNo: "Número de cliente final",
        freightContact: "Contacto del flete",
        missingCountry:
          "Se debe especificar un país antes de poder añadir los detalles del cliente. Seleccione uno en la vista Perfil del inquilino.",
        priceListId: "ID de la lista de precios de la tienda web"
      },
      messages: {
        removed: "Se han eliminado los datos del cliente",
        saved: "Se han guardado los datos del cliente"
      },
      placeholders: {
        address: "Introduzca la dirección",
        customerNo: "Introduzca un número de cliente de Monitor",
        customerType: "Elija el tipo de cliente",
        discount: "Introduzca el descuento de la tienda web (0-100 %)",
        freightContact: "Introduzca el contacto del flete",
        priceListId: "Introduzca la ID de la lista de precios de la tienda web"
      },
      title: "Cliente"
    },
    dataTable: {
      headers: {
        city: "Ciudad",
        country: "País",
        description: "Descripción",
        name: "Nombre"
      }
    },
    dialogs: {
      edit: {
        addTitle: "Añadir inquilino",
        city: "Ciudad",
        cityPlaceholder: "Introducir nombre de la ciudad",
        country: "País",
        countryPlaceholder: "Seleccionar país",
        description: "Descripción",
        descriptionPlaceholder: "Introducir descripción",
        editTitle: "Editar inquilino",
        engineHoursBeforeNextService:
          "Horas del motor antes del próximo mantenimiento",
        enterEmailAddress: "Introducir dirección de correo electrónico",
        messages: {
          added: "Se ha añadido el inquilino",
          parentChanged: "Se ha cambiado el progenitor del inquilino",
          updated: "Se ha actualizado el inquilino"
        },
        name: "Nombre",
        namePlaceholder: "Introducir nombre de inquilino",
        sendMaintanenceNotifications:
          "Enviar notificaciones por correo electrónico relacionadas con el mantenimiento."
      }
    },
    itemName: "inquilino",
    menuItems: {
      downloads: "Descargas",
      fleetAnalytics: "Fleet Analytics",
      fleet_analytics: "Panel de control de Fleet Analytics",
      machines: "Máquinas",
      manuals: "Manuales",
      manualsProductId: "Manuales para Brokk ",
      map: "Mapa",
      reports: "Informes",
      tenants: "Inquilinos",
      users: "Usuarios"
    },
    noLocationData: "No hay datos de ubicación para mostrar",
    profile: {
      title: "Perfil del inquilino"
    },
    selectAnObject: "Selecciona un objeto de la vista del árbol."
  }
}
