export default {
  users: {
    actions: {
      add: "Añadir usuario",
      edit: "Editar usuario",
      export:
        "Exportar todos los usuarios verificados que tienen acceso a la tienda web"
    },
    details: {
      accountVerified: "Cuenta verificada",
      name: "Nombre",
      title: "Datos del usuario"
    },
    dialogs: {
      edit: {
        descriptionPlaceholder: "Introducir descripción",
        emailPlaceholder: "Introducir dirección de correo electrónico",
        familyName: "Apellido",
        familyNamePlaceholder: "Introducir apellido",
        fleetAnalyticsAccess: "Permitir acceso a Fleet Analytics",
        givenName: "Nombre",
        givenNamePlaceholder: "Introducir nombre",
        messages: {
          userAdded: "Se ha añadido el usuario",
          userExport: "Usuarios exportados correctamente",
          userUpdated: "Se ha actualizado el usuario"
        },
        rolePlaceholder: "Seleccionar rol",
        webshopAccess: "Rol de acceso a la tienda web"
      }
    },
    itemAttributes: {
      description: "Descripción",
      email: "Correo electrónico",
      fleetAnalyticsAccess: "Acceso a Fleet Analytics",
      lastLogin: "Último inicio de sesión",
      name: "Nombre",
      role: "Rol",
      tenant: "Inquilino",
      webshopRole: "Rol de la tienda web"
    },
    itemName: "usuario",
    settings: {
      menuItems: {
        notifications: "Notificaciones",
        preferences: "Preferencias",
        profile: "Perfil",
        settings: "Ajustes"
      },
      notifications: {
        maintenance: "Mantenimiento",
        otherNotifications: "Otras notificaciones",
        rental: "Alquiler",
        sendWeeklyReports: "Enviar informes semanales",
        sendWeeklyReportsDesc:
          "Envía un informe con el estado de todos tus robots Brokk una vez a la semana.",
        title: "Notificaciones"
      },
      preferences: {
        description:
          "Aquí puedes definir tus propias preferencias accediendo a los valores predeterminados de la aplicación web como Idioma, Unidades, Página de inicio predeterminada, etc.",
        initialView: "Vista inicial",
        units: "Unidades"
      },
      profile: {
        title: "Perfil de usuario"
      }
    }
  }
}
