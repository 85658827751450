export default {
  machines: {
    actions: {
      addMachine: "Gerät registrieren ",
      clearProductFilters: null,
      importMachine: "Maschine importieren",
      provisioned: "Bereitgestellt",
      transfer: "Übertragen",
      unprovisioned: "Nicht bereitgestellt"
    },
    activities: {
      beltActiveTime: "Aktive Zeit am Gürtel",
      otherTime: "Andere Zeit",
      toolActiveTime: "Aktive Zeit des Werkzeugs"
    },
    alarms: {
      dialogs: {
        extraInfo: {
          dataTable: {
            headers: {
              category: "Kategorie",
              description: "Beschreibung",
              value: "Wert"
            }
          }
        }
      },
      moreExists: "mehr existiert"
    },
    dataTable: {
      headers: {
        active: "Aktiv",
        brokkConnect: "Brokk Connect",
        category: "Kategorie",
        code: "Code",
        date: "Datum",
        description: "Beschreibung",
        device: "Gerät",
        engineHours: "Motorbetriebsstunden",
        extraInfo: "Zusätzliche Informationen",
        id: "ID",
        lastSeen: "Zuletzt gesehen",
        name: "Name",
        number: "Nummer",
        product: "Produkt",
        serviceAgreement: "Servicevertrag",
        serviceStatus: "Servicestatus",
        severity: "Schweregrad",
        status: "Status",
        tenantName: "Mieter",
        updateInterval: "Aktualisierungsintervall"
      },
      tooltips: {
        cellular: "Brokk Connect verwendet mobile Daten",
        overdue: "Überfällig",
        pending: "Anhängig",
        service: "Service",
        serviceAgreementMissing: "Kein Servicevertrag vorhanden",
        serviceAgreementPresent: "Servicevertrag vorhanden",
        unknown: "Unbekannt",
        unprovisioned: "Brokk Connect ist nicht installiert",
        upcoming: "OK",
        wifi: "Brokk Connect verwendet WLAN"
      }
    },
    dialogs: {
      addMachine: {
        addMachineSucceededText: "Das Gerät Brokk {machine} wurde hinzugefügt.",
        confirmAddMachine: "Möchten Sie das Gerät hinzufügen?",
        confirmAddMachineProceedInfo:
          "Um das Gerät hinzuzufügen, wählen Sie ein Modell aus und drücken dann auf Weiter.",
        confirmAddMachineProceedInfoNext:
          "Um das Gerät hinzuzufügen, drücken Sie auf Weiter.",
        confirmAddMachineTitle: "Gerät hinzufügen?",
        engineHours: "Motorbetriebsstunden",
        enterEngineHoursTitle: "Motorbetriebsstunden eingeben",
        enterMachineInformationTitle: "Geräteinformationen eingeben",
        enterserialNoDescription: "Gültige Seriennummer eingeben",
        enterserialNoTitle: "Geräteseriennummer eingeben",
        errors: {
          couldNotAddMachine: "{msg}",
          couldNotRegisterService: "{msg}",
          couldNotUpdateService: "{msg}",
          noSuchserialNo: "Fehler: Diese Seriennummer existiert nicht."
        },
        serialNo: "Seriennummer",
        steps: {
          confirm: "Bestätigen",
          overview: "Übersicht",
          summary: "Zusammenfassung"
        },
        title: "Nicht bereitgestelltes Gerät hinzufügen"
      },
      addServiceSchedule: {
        comment: "Kommentar zu Servicearbeiten",
        commentHint:
          "Der Kommentar zu Servicearbeiten ist im Verlauf der Servicearbeiten sichtbar.",
        commentPlaceholder: "Kommentar zu den Servicearbeiten eingeben",
        engineHours: "Motorbetriebsstunden",
        enterEngineHoursTitle: "Motorbetriebsstunden eingeben",
        service2: "Servicearbeiten 2",
        service3: "Servicearbeiten 3",
        service4: "Servicearbeiten 4",
        serviceInformationTitle: "Informationen zu Servicearbeiten eingeben",
        serviceTitle: "Servicearbeiten",
        steps: {
          confirm: "Bestätigen",
          overview: "Übersicht",
          summary: "Zusammenfassung"
        },
        title: "Wartungsplan hinzufügen"
      },
      commissioning: {
        saved: "Inbetriebnahme erfolgreich gespeichert.",
        steps: {
          applications: {
            hint: "(Optional) Wählen Sie eine oder mehrere Anwendungen aus",
            name: "Anwendungen"
          },
          category: {
            name: "Kategorie",
            otherCategoryHint: "Bitte präzisieren Sie sonstige Kategorie",
            otherCategoryPlaceholder: "Sonstige Kategorie"
          },
          commissioning: {
            description:
              "Bitte wählen Sie das Datum der Inbetriebnahme der Maschine. Dieses bestimmt das Start- und Enddatum der Gewährleistung.",
            name: "Inbetriebnahme"
          },
          confirm: {
            description:
              "Bitte bestätigen Sie, dass die folgenden Angaben korrekt sind:",
            name: "Bestätigen",
            proceedInfo:
              "Um die Maschine in Betrieb zu nehmen, klicken Sie auf Speichern."
          },
          segment: {
            description:
              "Bitte wählen Sie ein Segment und eine Kategorie aus, die die geplante Verwendung der Maschine beschreibt.",
            name: "Segment"
          }
        },
        title: "Inbetriebnahme der Maschine"
      },
      editMachine: {
        createdTimestamp: "Erstellt",
        description: "Beschreibung",
        descriptionPlaceholder: "Geben Sie eine Beschreibung ein",
        name: "Name",
        namePlaceholder: "Geben Sie einen Namen ein",
        revision: "Überarbeitung",
        serialNo: "Seriennummer",
        tenant: "Mieter",
        tenantHint:
          "Der Mieter kann nicht gewechselt werden, solange eine Übertragung aussteht",
        tenantPlaceholder: "Mieter auswählen",
        title: "Maschine bearbeiten"
      },
      editServiceSchedule: {
        engineHours: "Motorbetriebsstunden",
        enterEngineHoursTitle: "Motorbetriebsstunden eingeben",
        service2: "Servicearbeiten 2",
        service3: "Servicearbeiten 3",
        service4: "Servicearbeiten 4",
        serviceInformationTitle: "Informationen zu Servicearbeiten eingeben",
        serviceTitle: "Servicearbeiten",
        steps: {
          confirm: "Bestätigen",
          overview: "Übersicht",
          summary: "Zusammenfassung"
        },
        title: "Wartungsplan hinzufügen"
      },
      editSettings: {
        cantEdit: "Dieser Parameter kann nicht bearbeitet werden",
        category: "Kategorie",
        description: "Beschreibung",
        descriptionPlaceholder: "Geben Sie eine Beschreibung ein",
        device: "Gerät",
        id: "ID",
        number: "Nummer",
        operatorMustConfirm:
          "Ihre Änderungen müssen von einem Betreiber genehmigt werden, bevor sie wirksam werden",
        title: "Aktualisierungsintervall bearbeiten",
        updateInterval: "Aktualisierungsintervall"
      },
      importMachine: {
        confirmTransferMachine:
          "Möchten Sie die {machine} mit der Seriennummer {sn} auf den aktuellen Mieter {tenant} übertragen?",
        confirmTransferProceedInfo:
          "Um die Maschine auf diesen Mieter zu übertragen, drücken Sie auf „Weiter“.",
        confirmTransferTitle: "Maschine übertragen?",
        enterTransferCodeDescription:
          "Geben Sie einen Übertragungscode ein, um eine Maschine von einem anderen Mieter auf den aktuell ausgewählten Mieter zu übertragen",
        enterTransferCodeTitle: "Übertragungscode eingeben",
        errors: {
          couldNotCompleteTransfer:
            "Fehler: Die Übertragung konnte nicht abgeschlossen werden. {msg}",
          noSuchTransferCode: "Fehler: Übertragungscode existiert nicht",
          transferCodeAlreadyUsed:
            "Fehler: Der Übertragungscode wurde bereits verwendet",
          transferCodeExpired: "Fehler: Der Übertragungscode ist abgelaufen",
          transferReverted: "Fehler: Die Übertragung wurde rückgängig gemacht"
        },
        steps: {
          confirm: "Bestätigen",
          overview: "Übersicht",
          summary: "Zusammenfassung"
        },
        title: "Maschine auf {tenant} übertragen",
        transferCode: "Übertragungscode",
        transferCodePlaceholder: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
        transferSucceededText:
          "Die {machine} mit der Seriennummer {sn} wurde auf {tenant} übertragen."
      },
      transferMachine: {
        errors: {
          internalError:
            "Aufgrund eines internen Fehlers konnte zu diesem Zeitpunkt kein Übertragungscode generiert werden.",
          machineAlreadyTransferred:
            "Diese Maschine wurde an einen anderen Mieter übertragen",
          pendingTransfer:
            "Die Übertragung dieser Maschine ist noch nicht abgeschlossen. Sie wurde erstellt",
          transferCodeExpires: "Der Übertragungscode läuft ab"
        },
        refreshTheList: "Bitte aktualisieren Sie die Maschinenliste.",
        steps: {
          confirm: {
            description:
              "Übertragung des Eigentums an der Maschine {sn} ({productName}), die derzeit im Besitz von {tenant} ist?",
            includeHistoricalDataInfo:
              "Die Übertragung wird auch Verlaufsdaten umfassen.",
            name: "Bestätigen",
            notIncludeHistoricalDataInfo:
              "Die Übertragung umfasst keine Verlaufsdaten.",
            proceedInfo:
              "Um einen Übertragungscode zu generieren und die Übertragung einzuleiten, drücken Sie auf Weiter.",
            title: "Bestätigen"
          },
          dataRetention: {
            description:
              "Wenn Sie die Verlaufsdaten der Maschine in die Übertragung einbeziehen, kann der Empfänger alle bisherigen Alarme, Ereignisse und Messungen einschließlich der Geolokalisierung einsehen.",
            includeHistoricalData:
              "Verlaufsdaten in die Übertragung einbeziehen.",
            name: "Datenspeicherung",
            title: "Verlauf einbeziehen"
          },
          summary: {
            description:
              "Kopieren Sie den unten stehenden Übertragungscode und senden Sie ihn per E-Mail an den Empfänger, der ihn zum Abschließen der Übertragung verwendet.",
            name: "Zusammenfassung",
            title: "Übertragungscode"
          },
          transferOwnership: {
            description:
              "Übertragen Sie das Eigentum an dieser Maschine mit einem Übertragungscode auf einen anderen Mieter. Die Maschine bleibt zugänglich, solange die Übertragung noch nicht abgeschlossen ist.",
            name: "Übersicht",
            title: "Eigentum übertragen"
          }
        },
        title: "Maschine übertragen",
        transferAutomaticRevertInfo:
          "Die Übertragung wird automatisch rückgängig gemacht, wenn der Empfänger die Übertragung nicht innerhalb von 3 Tagen abschließt.",
        transferCode: "Übertragungscode"
      }
    },
    dieselParticulateFilter: {
      info: {
        error: "Kontaktieren Sie den Kundendienst von Brokk.",
        ok: "Status OK.",
        title: "Dieselpartikelfilter (DPF)",
        warning:
          "Manuelle Regeneration erforderlich. Siehe Anzeige auf der Maschine."
      },
      status: {
        ashLevel: "Aschegehalt",
        sootLevel: "Rußgehalt",
        title: "DPF-Status"
      }
    },
    engineHoursSelectionList: {
      lastDay: "Letzter Tag",
      lastMonth: "Letzter Monat",
      lastWeek: "Letzte Woche",
      total: "Gesamt"
    },
    featureNotAvailable:
      "Die Mietfunktion ist auf dieser Maschine derzeit nicht aktiviert.",
    filters: {
      dataSource: "Datenquelle"
    },
    info: {
      order: {
        commissioningDate: "Datum der Inbetriebnahme",
        commissioningHint: "Max. 6 Monate ab Lieferdatum",
        deliveryDate: "Lieferdatum",
        label: "Bestellung der Maschine",
        orderNumber: "Bestellnummer",
        orderType: "Art der Bestellung"
      },
      segment: {
        actions: {
          commission: "Maschine in Betrieb nehmen"
        },
        applications: "Anwendungen",
        category: "Kategorie",
        label: "Segment",
        overlay: {
          description:
            "Nehmen Sie die Maschine in Betrieb, um die Gewährleistung zu aktivieren, und legen Sie fest, für welche Anwendungen sie verwendet werden soll.",
          disabledDescription:
            "Die Inbetriebnahme ist möglich, sobald für die Maschine einen Liefertermin und eine Gewährleistungsdauer vorliegt.",
          heading: "Dies ist eine Standardmaschine"
        }
      },
      warranty: {
        endDate: "Enddatum",
        label: "Gewährleistung",
        length: "Dauer",
        startDate: "Startdatum"
      }
    },
    labels: {
      activeAlarms: "Aktive Alarme",
      elMotorTemp: "Temperatur des Elektromotors",
      engineHours: "Motorbetriebsstunden",
      engineHoursDays: "Motorbetriebsstunden / Tage",
      fuelLevel: "Kraftstoffstand",
      highPressureTime: "Zeit bei hohem Druck",
      highTempTime: "Zeit bei hoher Temperatur",
      hydraulicFluidTemp: "Temperatur der Hydraulikflüssigkeit",
      machineActivity: "Aktivität der Maschine",
      nextServiceIn: "Nächster Service in",
      noMachines: {
        descriptionHasAccess:
          "Klicken Sie auf die Schaltfläche unten, um ein nicht bereitgestelltes Gerät zu registrieren. So haben Sie sofort Zugriff auf die richtigen Unterlagen und Ersatzteile.",
        descriptionNoAccess:
          "Wenn Sie Ihr Gerät registrieren, erhalten Sie sofort Zugang zu den richtigen Unterlagen und Ersatzteilen. Wenden Sie sich an den nächsten Händler, um Ihr Gerät zu registrieren.",
        title: "Gerät registrieren"
      },
      notification: "Benachrichtigung"
    },
    location: {
      currentMachineSettings: "Aktuelle Maschineneinstellungen",
      dialogs: {
        extraInfo: {
          dataTable: {
            headers: {
              propertyCategory: "Kategorie",
              propertyName: "Name",
              propertyValue: "Wert"
            }
          }
        }
      },
      events: {
        dataTable: {
          headers: {
            date: "Datum",
            description: "Beschreibung",
            extraData: "Zusätzliche Informationen",
            source: "Quelle"
          }
        }
      },
      geofencingSettings: {
        actions: {
          add: "Geofence hinzufügen",
          edit: "Geofence-Einstellungen bearbeiten",
          remove: "Geofence-Einstellungen entfernen"
        },
        confirm: {
          description:
            " Sind Sie sicher, dass Sie die Geofence-Änderungen übernehmen möchten? Dies kann die Funktionstüchtigkeit der Maschine beeinträchtigen. Vergewissern Sie sich, dass die Maschine so abgestellt ist, dass sie keine Schäden an Personen oder Geräten verursacht.",
          title: "Geofence anwenden"
        },
        coordinates: "Koordinaten",
        description: " ",
        latitude: "Breitengrad",
        latitudePlaceholder: "Breitengrad eingeben",
        limit: {
          description: "",
          limitation: "Beschränkung",
          limitationPlaceholder:
            "Wählen Sie eine Beschränkung, die Sie hinzufügen möchten",
          title: "Beschränkungen"
        },
        longitude: "Längengrad",
        longitudePlaceholder: "Längengrad eingeben",
        messages: {
          anyLimitationOrNotificationRequired:
            "Bitte geben Sie eine oder mehrere Benachrichtigungen an",
          disabled:
            "Die Geofence-Funktion ist auf dieser Maschine nicht aktiviert.",
          noLimitationsAdded: "Noch keine Beschränkungen hinzugefügt.",
          settingsNotSynched:
            "Die Geofence-Einstellungen wurden von der Maschine nicht synchronisiert.",
          settingsRemoved: "Der Geofence wurde entfernt",
          settingsSaved: "Der Geofence wurde gespeichert"
        },
        notifications: {
          description:
            "Geben Sie die E-Mail-Adressen der Personen ein, die E-Mail-Benachrichtigungen erhalten sollen, wenn die Maschine in den Geofence-Bereich eintritt oder diesen verlässt.",
          title: "Benachrichtigungen"
        },
        radius: "Radius",
        radiusPlaceholder: "Radius eingeben",
        title: "Geofence",
        triggers: {
          enter: "Eintritt",
          exit: "Austritt"
        },
        unit: "Einheit",
        unitPlaceholder: "Einheit eingeben"
      },
      geolocationSettings: {
        actions: {
          edit: "Geolokalisierungs-Einstellungen bearbeiten",
          remove: "Geolokalisierungs-Einstellungen entfernen"
        },
        description: "Einstellungen für die Offline-Geolokalisierung",
        distance: "Entfernung",
        distanceHint:
          "Entfernung, die die Maschine mindestens zurücklegen muss, bevor sie eine Aktualisierung sendet",
        distancePlaceholder: "Entfernung eingeben",
        interval: "Intervall",
        intervalHint:
          "Zeitintervall, das zwischen den Aktualisierungen mindestens vergehen muss",
        intervalPlaceholder: "Intervall wählen",
        messages: {
          disabled:
            "Die Geolokalisierungsfunktion ist derzeit auf dieser Maschine nicht aktiviert.",
          settingsNotSynched:
            "Die Geolokalisierungs-Einstellungen wurden von dieser Maschine nicht synchronisiert.",
          settingsRemoved:
            "Die Geolokalisierungs-Einstellungen wurden entfernt",
          settingsSaved:
            "Die Geolokalisierungs-Einstellungen wurden gespeichert"
        },
        movement: "Bewegung",
        timer: "Timer",
        title: "Geolokalisierungs-Einstellungen",
        unit: "Einheit",
        unitPlaceholder: "Einheit auswählen"
      },
      location: {
        actions: {
          setGeofencingLocation: "Geofence-Standort hier festlegen"
        },
        description:
          "Definieren Sie einen Bereich, in dem die Maschine arbeiten soll, und erhalten Sie eine Benachrichtigung, wenn sie in diesen Bereich eintritt oder diesen verlässt.",
        messages: {
          changeGeofencingCoordinatesTip:
            "Mit der Schaltfläche „Kreis zeichnen“ auf der Karte können Sie den Geofence der Maschine definieren oder ändern."
        },
        showHistory: "Standortverlauf anzeigen",
        title: "Standort-Einstellungen"
      },
      map: {
        geofenceCenter: "Geofence-Zentrum",
        machineLocation: "Standort der Maschine"
      }
    },
    maintenance: {
      service: {
        add: "Servicearbeiten erfolgreich hinzugefügt",
        beforeNextServiceYearlyService:
          "Vor dem nächsten Service / Jährlicher Service",
        commentMaxChars: "Ein Kommentar darf maximal 2000 Zeichen lang sein.",
        overdue: "Servicearbeiten überfällig",
        reset: {
          actions: {
            send: "Befehl zum Zurücksetzen des Services senden"
          },
          availableForServiceUsersOnly:
            "Die Funktion zum Zurücksetzen des Services ist nur für Servicemitarbeiter verfügbar.",
          comment: "Service-Kommentar",
          commentHint:
            "Der Service-Kommentar wird im Service-Verlauf sichtbar sein",
          commentPlaceholder: "Geben Sie einen Kommentar zum Service ein",
          confirm: {
            description:
              "Sind Sie sicher, dass Sie eine Servicerückstellung für Maschine <strong>{0}</strong> durchführen wollen?",
            note: "Hinweis: Diese Aktion kann nicht rückgängig gemacht werden.",
            title: "Servicerückstellung bestätigen"
          },
          description:
            "Dies sendet einen Befehl zur Servicerückstellung an die Maschine und funktioniert genauso wie eine Servicerückstellung mit dem Brokk Config Tool.",
          disabled:
            "Die Funktion zum Zurücksetzen des Services ist auf dieser Maschine derzeit nicht aktiviert.",
          errors: {
            notAvailable:
              "Eine Servicerückstellung kann 100 Stunden vor  dem geplanten Service durchgeführt werden oder wenn ein jährlicher Service ansteht.",
            offline:
              "Die Maschine muss online sein, damit ein Service zurückgesetzt werden kann.",
            waitingForYearly:
              "Der jährliche Service wurde auf der Maschine noch nicht aktiviert. Es kann eine Stunde dauern, bis sie aktiv wird. Bitte warten Sie und versuchen Sie es später noch einmal."
          },
          success: {
            description:
              "Der Service für Maschine <strong>{0}</strong> wurde erfolgreich zurückgesetzt!",
            title: "Servicerückstellung erfolgreich"
          },
          title: "Servicerückstellung"
        },
        schedule: {
          dataTable: {
            headers: {
              comment: "Kommentar",
              loggedHours: "Erfasste Stunden",
              scheduled: "Geplant",
              service: "Service",
              serviceDate: "Service-Datum",
              status: "Status"
            }
          },
          description:
            "Der Wartungsplan basiert auf der Betriebszeit und dem letzten ServiceDatum der Maschine. Da die Arbeitsbedingungen unterschiedlich sein können, kann esnotwendig sein, das Serviceintervall an die jeweiligen Arbeits-und Umgebungsbedingungen anzupassen.",
          received: "Empfangen",
          title: "Wartungsplan"
        },
        update: "Servicearbeiten erfolgreich aktualisiert."
      },
      serviceAgreement: {
        actions: {
          add: "Neuen Servicevertrag hinzufügen",
          edit: "Servicevertrag bearbeiten"
        },
        labels: {
          description: "Beschreibung",
          endDate: "Enddatum",
          filename: "Name der Datei",
          fileuploadLabel: "Servicevertragsdokument",
          startDate: "Startdatum",
          uploadFilePlaceholder:
            "Laden Sie das Dokument hoch, das den angegebenen Servicevertrag enthält"
        },
        messages: {
          added: "Es wurde ein neuer Servicevertrag hinzugefügt",
          deleted: "Der Servicevertrag wurde gelöscht",
          failedToDownload:
            "Fehler beim Herunterladen des Servicevertragsdokuments {file}",
          updated: "Der Servicevertrag wurde aktualisiert"
        },
        title: "Servicevertrag",
        warnings: {
          isExpired: "Der Servicevertrag ist abgelaufen"
        }
      }
    },
    manuals: {
      actions: {
        downloadFile: "Datei herunterladen"
      },
      dataTable: {
        headers: {
          fileName: "Name der Datei"
        }
      },
      messages: {
        failedToDownload: "Download von {file} fehlgeschlagen"
      }
    },
    measurements: {
      electricMotor: "Elektromotor",
      electronicUnit: "Elektronikeinheit",
      engineHours: "Motorbetriebsstunden",
      export: {
        csv: {
          columns: {
            category: "Kategorie",
            description: "Beschreibung",
            parameterId: "Parameter-ID",
            timestamp: "Zeitstempel",
            unit: "Einheit",
            value: "Wert"
          }
        }
      },
      gateway: "Gateway",
      highPressureTime: "Zeit bei hohem Druck",
      highTempTime: "Zeit bei hoher Temperatur",
      hydraulicFluid: "Hydraulikflüssigkeit",
      hydraulicFluidPressure: "Druck der Hydraulikflüssigkeit",
      temperatures: "Temperaturen"
    },
    menuItems: {
      alarms: "Alarme",
      dashboard: "Dashboard",
      info: "Info",
      location: "Standort",
      maintenance: "Wartung",
      manuals: "Handbücher",
      measurements: "Messungen",
      parameters: "Parameter",
      rental: "Vermietung",
      system: "System"
    },
    messages: {
      applyChangesPending:
        "Warten auf die Übernahme der Änderungen durch das Gerät",
      changesApplied: "Die Änderungen wurden übernommen",
      contactToLearnMore:
        "Wenn Sie mehr erfahren möchten, wenden Sie sich bitte an",
      errorApplyingChanges: "Fehler beim Anwenden von Änderungen",
      fetchingMachineDetails: "Abruf von Maschinendaten ...",
      machineAdded: "Die Maschine wurde hinzugefügt",
      machineDetailsAreBeeingFetched:
        "Die Daten der Maschine werden abgerufen. Bitte warten.",
      machineDoesNotLoadInfo:
        " Wenn die Maschine nicht geladen wird, gehen Sie bitte zurück und aktualisieren Sie die Maschinenansicht manuell.",
      machineOffline: "Die Maschine ist offline.",
      machineUpdated: "Die Maschine wurde aktualisiert",
      unsavedChanges: "Nicht gespeicherte Änderungen"
    },
    missingMachineImage: "Fehlendes Produktbild",
    notFound: "Maschine nicht gefunden",
    notifications: {
      settings: {
        actions: {
          edit: "Benachrichtigungseinstellungen bearbeiten",
          remove: "Benachrichtigungseinstellungen entfernen",
          send: "Benachrichtigung senden"
        },
        daysBeforeLeaseExpiration: "Tage bis zum Leasingablauf ",
        daysBeforeNextYearlyService: "Tage vor dem nächsten jährlichen Service",
        description:
          "Geben Sie die E-Mail-Adressen der Personen ein, die E-Mail-Benachrichtigungen erhalten sollen.",
        dialogs: {
          sendNotification: {
            confirm:
              "Möchten Sie eine E-Mail-Benachrichtigung an {count} Empfänger senden? | Möchten Sie eine E-Mail-Benachrichtigung an {count} Empfänger senden?",
            successMessage:
              "E-Mail-Benachrichtigung wurde gesendet | E-Mail-Benachrichtigungen wurden gesendet"
          }
        },
        engineHoursBeforeLeaseExpires:
          "Motorbetriebsstunden vor Ablauf des Mietvertrags",
        engineHoursBeforeNextService:
          "Motorbetriebsstunden vor dem nächsten Service",
        maxRecipientsHint:
          "Es können maximal 5 E-Mail-Adressen hinzugefügt werden",
        messages: {
          notificationSettingsRemoved:
            "Benachrichtigungseinstellungen wurden entfernt",
          notificationSettingsSaved:
            "Benachrichtigungseinstellungen wurden gespeichert"
        },
        noLeaseAdded:
          "Um E-Mail-Benachrichtigungen hinzuzufügen, kehren Sie bitte zur Registerkarte Mietvertrag zurück und geben einen Mietvertrag an.",
        norecipientsAdded: "Noch keine Empfänger hinzugefügt.",
        recipients: "Empfänger",
        selectEmails:
          "Wählen Sie bis zu 5 E-Mail-Adressen, die Benachrichtigungen erhalten sollen.",
        sendHint: "Senden Sie jetzt eine E-Mail an alle Empfänger",
        threshold: "Schwellenwert",
        title: "Benachrichtigungseinstellungen"
      }
    },
    parameters: {
      dataTable: {
        headers: {
          category: "Kategorie",
          description: "Beschreibung",
          device: "Gerät",
          id: "ID",
          number: "Nummer",
          value: "Wert"
        }
      },
      dialogs: {
        editParameter: {
          category: "Kategorie",
          messages: {
            cantBeEdited: "Dieser Parameter kann nicht bearbeitet werden",
            changesNeedApproval:
              "Ihre Änderungen müssen von einem Betreiber genehmigt werden, bevor sie wirksam werden"
          },
          parameterNo: "Parameter Nr.",
          updateinterval: "Aktualisierungsintervall"
        }
      }
    },
    props: {
      description: "Beschreibung",
      lastSeen: "Zuletzt gesehen",
      name: "Name",
      product: "Produkt",
      serialNo: "Seriennummer",
      serviceStatus: "Servicestatus",
      status: "Status",
      tenant: "Mieter"
    },
    rental: {
      lease: {
        notDefined: {
          description:
            "Klicken Sie auf die Schaltfläche unten, um einen Mietvertrag hinzuzufügen. <br/>Dies führt Sie durch die notwendigen Schritte, um die Mietfunktion für Ihr Gerät zu nutzen.",
          title: "Es wurde kein Mietvertrag definiert."
        },
        notEnabled: {
          description:
            "Die Maschinenvermietung kann als Add-on erworben werden.<br/>Wenn Sie mehr erfahren möchten, wenden Sie sich bitte an",
          title:
            "Die Mietfunktion ist auf dieser Maschine derzeit nicht aktiviert."
        },
        notSupportingDate:
          "Dieses Gerät erlaubt nur die Vermietung nach Motorstunden.",
        settings: {
          actions: {
            add: "Einstellungen für Mietvertrag hinzufügen",
            edit: "Mieteinstellungen bearbeiten",
            remove: "Mieteinstellungen entfernen"
          },
          configuredLeaseSettings: "Konfigurierte Mieteinstellungen.",
          confirm: {
            description:
              " Sind Sie sicher, dass Sie den Mietvertrag bestätigen wollen? Dies kann die Funktionstüchtigkeit der Maschine beeinträchtigen. Vergewissern Sie sich, dass die Maschine so abgestellt ist, dass sie keine Schäden an Personen oder Geräten verursacht.",
            title: "Aktualisierung des Mietvertrags bestätigen"
          },
          description:
            " Beschränkungen für die Maschine nach Erreichen der angegebenen Motorbetriebsstunden festlegen",
          engineHours: "Motorbetriebsstunden",
          engineHoursHover:
            "Die letzten bekannten, von der Maschine gemeldeten Motorbetriebsstunden",
          headers: {
            active: "Aktiv",
            requested: "Angefordert"
          },
          leaseEnd: "Leasingende",
          leaseSettingsReportedByMachine:
            "Von der Maschine gemeldete Mieteinstellungen.",
          leaseStatusDescription: {
            applied: "Die Änderungen wurden übernommen",
            notApplicable: "K.A.",
            pending: "Warten auf das Gerät"
          },
          machineRestrictions: "Geräte-Einschränkungen",
          messages: {
            emailNotificationHasBeenSent:
              "E-Mail-Benachrichtigung wurde gesendet",
            leaseUpdateRequestHasBeenSent:
              "Eine Anfrage zur Aktualisierung des Mietvertrags wurde an die Maschine gesendet. Eine Benachrichtigung wird verschickt, wenn sie bestätigt wurde."
          },
          restrictionTypes: {
            blinkLights: "Blinkleuchten",
            disableToolA: "Werkzeug A deaktivieren",
            reduceMovementSpeedToLvl3:
              "Bewegungsgeschwindigkeit auf Geschwindigkeitsreduzierungsstufe 3 reduzieren",
            stopEngineAfter: "Motor abstellen nach"
          },
          sendEmailNotificationAboutLeaseChange:
            "Möchten Sie jetzt eine E-Mail-Benachrichtigung senden, um den Betreiber darüber zu informieren, dass sich die Mieteinstellungen ändern werden?",
          title: "Mieteinstellungen"
        }
      }
    },
    service: "Service",
    system: {
      newVersionAvailable: "Es steht eine neue Version zum Download bereit.",
      newVersionDetails: "Version {version}, freigegeben {releaseDate}.",
      noDataDescription:
        "Dies ist normal, wenn die Maschine neu ist und noch keineWerte gemeldet hat. Bitte überprüfen Sie dies nach ein paar Motorbetriebsstunden erneut."
    },
    tabHeaders: {
      alarms: "Alarme",
      events: "Ereignisse",
      lease: "Mietvertrag",
      location: "Standort",
      measurements: "Messungen",
      notifications: "Benachrichtigungen",
      service: "Service",
      serviceAgreement: "Servicevertrag",
      settings: "Einstellungen"
    },
    updated: "Aktualisiert {TIME}"
  }
}
