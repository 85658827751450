<template>
  <div class="text-center d-inline-flex fluid">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      offset-y
      nudge-right="8"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn small text v-bind="attrs" v-on="on" :class="{ active: menu }">
          {{ filterName }}
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <v-card min-width="290px">
        <v-card-text>
          <p>{{ availableHoursRange[0] }} - {{ availableHoursRange[1] }}</p>
          <v-range-slider
            v-model="range"
            :min="availableHoursRange[0]"
            :max="availableHoursRange[1]"
            prepend-icon="mdi-engine"
          ></v-range-slider>
          <p>Selected Range: {{ range[0] }} h ~ {{ range[1] }} h</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="onApply">
            {{ $t("common.actions.apply") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: ["filterName", "availableHoursRange", "selectedHoursRange"],
  data: () => ({
    menu: false,
    range: [0, 0]
  }),
  created() {
    this.range = this.availableHoursRange
  },
  methods: {
    onApply() {
      this.$emit("update:selectedHoursRange", this.range)
      this.menu = false
    }
  },
  watch: {
    selectedHoursRange(newSelection) {
      this.range = newSelection
    },
    menu(newMenuState) {
      if (!newMenuState) {
        // Revert any changes that were not applied using the button.
        this.range = this.selectedHoursRange
      }
    }
  }
}
</script>

<style>
.v-btn.active .v-icon {
  transform: rotate(-180deg);
}
</style>
