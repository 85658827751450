import fetchClient from "@/utils/fetchClient"

export default {
  state: {
    warrantyClaims: {
      links: [
        {
          type: "HTTPLink",
          displayname: "AM system login",
          file: "https://rv456.amsystem.com/",
          icon: "mdi-open-in-new"
        },
        {
          type: "S3",
          displayname: "Warranty procedures",
          file:
            "1282_1_Warranty_procedures_for_Subsidiary_companies__Distributors_Agents.pdf",
          icon: "mdi-download"
        }
      ]
    },
    returnForm: {
      links: [
        {
          type: "S3",
          displayname: "Return Form",
          file: "RETURN_FORM2.PDF",
          icon: "mdi-rotate-360"
        }
      ]
    },
    serviceProtocols: {
      links: [
        {
          type: "S3",
          displayname: "Service 1000-hours",
          file: "Service_1000-hours.pdf",
          icon: "mdi-folder-wrench-outline"
        },
        {
          type: "S3",
          displayname: "Service 500-hours",
          file: "Service_500-hours.pdf",
          icon: "mdi-folder-wrench-outline"
        },
        {
          type: "S3",
          displayname: "Service 250-hours",
          file: "Service_250-hours.pdf",
          icon: "mdi-folder-wrench-outline"
        },
        {
          type: "S3",
          displayname: "Service 40-hours",
          file: "Service_40-hours.pdf",
          icon: "mdi-folder-wrench-outline"
        },
        {
          type: "S3",
          displayname: "Service weekly",
          file: "Service_weekly.pdf",
          icon: "mdi-folder-wrench-outline"
        },
        {
          type: "S3",
          displayname: "Service yearly",
          file: "Service_yearly.pdf",
          icon: "mdi-folder-wrench-outline"
        }
      ]
    },
    serviceManuals: {
      links: [
        {
          type: "S3",
          displayname: "Brokk 40 970201-",
          file: "800955.PDF",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname: "Brokk MiniCut 970001-970200",
          file: "800675-a.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname: "Brokk 90 920001-",
          file: "801124-GBB.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname: "Brokk 180 940800-",
          file: "801107-GBD.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname: "Brokk 250 910540-910647",
          file: "801076-A.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname: "Brokk 250 910648-",
          file: "801082-a.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname: "Brokk 330 digital 980001-",
          file: "800965-B.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname: "Brokk 330 analog 980001-",
          file: "800966-A.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname:
            "Brokk 110/150 digital 940200-940479, 940481-940504, 940507-940576, 940579-940589",
          file: "800643-A.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname:
            "Brokk 110/150 digital 940480, 940505-940506, 940577-940578, 940590-",
          file: "801000-b.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname: "Brokk 110/150 analog 940200-",
          file: "801034-a.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname: "Brokk 70+ - 900+, United Kingdom",
          file: "3136 8092 94_Service_(1.2)_en_GB.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname: "Brokk 70+ - 900+, Sweden",
          file: "3136 8092 94_Service_(1.2)_sv_SE.pdf",
          icon: "mdi-tools"
        },
        {
          type: "S3",
          displayname: "Brokk 70+ - 900+, Electrical Diagram",
          file: "Service Electrical diagram B70-B500 3136 8092 50-B.pdf",
          icon: "mdi-tools"
        }
      ]
    }
  },
  actions: {
    async addServiceDocuments(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true)

        const productsData = data.products.map(str => {
          const [, productId, serials] = str.split(" ")
          return { productId, serials }
        })

        let url = ""
        if (data.docType === "serviceInstructions") {
          url = "/api/v1/documents/serviceinstructions"
        } else {
          url = "/api/v1/documents/servicebulletins"
        }

        fetchClient(this.state.$i18n)
          .post(url, {
            partNo: data.partNo,
            document: data.document,
            description: data.description,
            products: productsData
          })
          .then(() => {
            resolve({})
          })
          .catch(error => {
            var errMsg = error.message

            if (error.causedBy) {
              errMsg = error.toString()
            }
            reject(errMsg)
          })
          .finally(() => {
            context.commit("setLoading", false)
          })
      })
    },
    async updateServiceInstructions(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true)

        const productsData = data.products.map(str => {
          const [, productId, serials] = str.split(" ")
          return { productId, serials }
        })

        fetchClient(this.state.$i18n)
          .put("/api/v1/documents/serviceinstructions/" + data.partNo, {
            document: data.document,
            description: data.description,
            products: productsData
          })
          .then(() => {
            resolve({})
          })
          .catch(error => {
            var errMsg = error.message

            if (error.causedBy) {
              errMsg = error.toString()
            }
            reject(errMsg)
          })
          .finally(() => {
            context.commit("setLoading", false)
          })
      })
    },
    async updateServiceDocuments(context, data) {
      let url = ""
      if (data.docType === "serviceInstructions") {
        url = "/api/v1/documents/serviceinstructions/" + data.partNo
      } else {
        url = "/api/v1/documents/servicebulletins/" + data.partNo
      }

      return new Promise((resolve, reject) => {
        context.commit("setLoading", true)

        const productsData = data.products.map(str => {
          const [, productId, serials] = str.split(" ")
          return { productId, serials }
        })

        fetchClient(this.state.$i18n)
          .put(url, {
            document: data.document,
            description: data.description,
            products: productsData
          })
          .then(() => {
            resolve({})
          })
          .catch(error => {
            var errMsg = error.message

            if (error.causedBy) {
              errMsg = error.toString()
            }
            reject(errMsg)
          })
          .finally(() => {
            context.commit("setLoading", false)
          })
      })
    },
    async removeServiceDocuments(context, data) {
      let url = ""
      if (data.docType === "serviceInstructions") {
        url = "/api/v1/documents/serviceinstructions/" + data.partNo
      } else {
        url = "/api/v1/documents/servicebulletins/" + data.partNo
      }
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true)
        fetchClient(this.state.$i18n)
          .delete(url)
          .then(() => {
            context.commit("clearError")
            resolve()
          })
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoading", false)
          })
      })
    }
  },
  getters: {
    warrantyClaims: state => {
      return state.warrantyClaims
    },
    returnForm: state => {
      return state.returnForm
    },
    serviceProtocols: state => {
      return state.serviceProtocols
    },
    serviceManuals: state => {
      return state.serviceManuals
    },
    spareParts: state => {
      return state.spareParts
    }
  }
}
