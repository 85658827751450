export default {
  users: {
    actions: {
      add: "Benutzer hinzufügen",
      edit: "Benutzer bearbeiten",
      export:
        "Alle verifizierten Benutzer exportieren, die Zugang zum Webshop haben."
    },
    details: {
      accountVerified: "Konto verifiziert",
      name: "Name",
      title: "Angaben zum Benutzer"
    },
    dialogs: {
      edit: {
        descriptionPlaceholder: "Geben Sie eine Beschreibung ein",
        emailPlaceholder: "Geben Sie eine E-Mail Adresse ein",
        familyName: "Familienname",
        familyNamePlaceholder: "Geben Sie den Familiennamen",
        fleetAnalyticsAccess:
          "Zugriff auf Fleet Analytics (Flottenanalyse) zulassen",
        givenName: "Vornamen",
        givenNamePlaceholder: "Geben Sie den Vornamen ein",
        messages: {
          userAdded: "Benutzer wurde hinzugefügt",
          userExport: "Benutzer erfolgreich exportiert",
          userUpdated: "Benutzer wurde aktualisiert"
        },
        rolePlaceholder: "Rolle auswählen",
        webshopAccess: "Rolle für Webshop-Zugang"
      }
    },
    itemAttributes: {
      description: "Beschreibung",
      email: "E-Mail",
      fleetAnalyticsAccess: "Zugriff auf Fleet Analytics (Flottenanalyse)",
      lastLogin: "Letzte Anmeldung",
      name: "Name",
      role: "Rolle",
      tenant: "Mieter",
      webshopRole: "Webshop-Rolle "
    },
    itemName: "Benutzer",
    settings: {
      menuItems: {
        notifications: "Benachrichtigungen",
        preferences: "Präferenzen",
        profile: "Profil",
        settings: "Einstellungen"
      },
      notifications: {
        maintenance: "Wartung",
        otherNotifications: "Sonstige Benachrichtigungen",
        rental: "Vermietung",
        sendWeeklyReports: "Wöchentliche Berichte senden",
        sendWeeklyReportsDesc:
          "Senden Sie einmal pro Woche einen Bericht mit dem Status aller Ihrer Brokk-Roboter.",
        title: "Benachrichtigungen"
      },
      preferences: {
        description:
          "Hier können Sie Ihre eigenen Präferenzen entsprechend den Standardeinstellungen der Webanwendung festlegen, z. B. Sprache, Einheiten, Standard-Startseite usw.",
        initialView: "Erste Ansicht",
        units: "Einheiten"
      },
      profile: {
        title: "Benutzerprofil"
      }
    }
  }
}
