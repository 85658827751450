<template>
  <v-container fluid class="main-background">
    <v-row>
      <v-col cols="auto">
        <!-- left menu list -->
        <v-list dark dense color="#3d3d3e">
          <v-list-item>
            <v-img
              contain
              :src="$store.getters.logoWhite"
              class="mb-10"
              max-width="200"
            ></v-img>
          </v-list-item>

          <div v-for="item in menuItems" :key="item.title">
            <v-list-group
              color="white"
              v-if="item.children"
              :value="true"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-title class="text-uppercase">
                  {{ item.title }}
                </v-list-item-title>
              </template>

              <div v-for="child in item.children" :key="child.title">
                <v-list-item
                  dark
                  v-if="canAccess(child)"
                  class="pl-10"
                  :class="child.styling"
                  :link="child.link !== null"
                  :to="expandedLink(child.link)"
                  @click.stop="onMenuClick(child)"
                >
                  <v-list-item-icon v-if="child.itemIcon" class="mr-2">
                    <v-icon dense :disabled="!canAccess(child)">{{
                      child.itemIcon
                    }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-uppercase">
                      {{ child.title }}
                      <v-icon
                        dense
                        :disabled="!canAccess(child)"
                        v-if="child.icon"
                        >{{ child.icon }}</v-icon
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list-group>
            <v-list-item
              v-else
              dark
              :disabled="!canAccess(item)"
              :class="item.styling"
              :link="true"
              :to="expandedLink(item.link)"
              @click.stop="onMenuClick(item)"
            >
              <v-list-item-icon v-if="item.itemIcon" class="mr-2">
                <v-icon dense :disabled="!canAccess(item)">{{
                  item.itemIcon
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-uppercase">
                  {{ item.title }}
                  <v-icon
                    dense
                    dark
                    :disabled="!canAccess(item)"
                    v-if="item.icon"
                    >{{ item.icon }}</v-icon
                  >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </v-col>

      <!-- right-hand side content view -->
      <v-col cols="9,auto" class="home-background">
        <v-row>
          <v-col class="ma-0 pa-0">
            <HomeToolbar :numUnreadMessages="numUnreadMessages" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="ma-0 pa-0">
            <router-view
              @unreadMessageCount="onUpdateUnreadMessages"
            ></router-view>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HomeToolbar from "@/components/home/HomeToolbar"

export default {
  components: {
    HomeToolbar
  },
  data() {
    return {
      numUnreadMessages: 0
    }
  },
  async created() {
    this.computeUnreadMessages()
  },
  computed: {
    selectedTenant() {
      return this.$store.getters.selectedTenant
    },
    tenantUid() {
      if (this.selectedTenant !== null) {
        return this.selectedTenant.uid
      }
      return this.$store.getters.tenantRoot?.uid
    },
    menuItems() {
      return [
        {
          title: this.$t("mainMenu.home"),
          itemIcon: "mdi-home",
          link: "/home",
          styling:
            "nav-item-border-bottom nav-item-border-top grab-home-nav-home"
        },
        {
          title: "brokk connect",
          link: null,
          styling: "nav-item-border-bottom",
          children: [
            {
              title: this.$t("tenant.menuItems.map"),
              itemIcon: "mdi-map-marker",
              link: `/tenants/${this.tenantUid}/map`,
              styling:
                "nav-item-border-bottom nav-item-border-top grab-home-nav-map",
              access: "canAccessMap"
            },
            {
              title: this.$t("tenant.menuItems.machines"),
              itemIcon: "mdi-progress-wrench",
              link: `/tenants/${this.tenantUid}/machines`,
              styling: "nav-item-border-bottom grab-home-nav-machines",
              access: "canAccessMachines"
            },
            {
              title: this.$t("tenant.menuItems.users"),
              itemIcon: "mdi-account-multiple",
              link: `/tenants/${this.tenantUid}/users`,
              styling: "nav-item-border-bottom grab-home-nav-users",
              access: "canAccessUsers"
            },
            {
              title: this.$t("tenant.menuItems.reports"),
              itemIcon: "mdi-finance",
              link: `/tenants/${this.tenantUid}/reports`,
              styling: "nav-item-border-bottom grab-home-nav-reports",
              access: "canAccessReports"
            },
            {
              title: this.$t("tenant.menuItems.fleetAnalytics"),
              itemIcon: "mdi-cog-outline",
              link: `/tenants/${this.tenantUid}/fleet_analytics`,
              styling: "nav-item-border-bottom grab-home-nav-fleetAnalytics",
              access: "canReadFleetAnalytics"
            }
          ]
        },
        {
          title: this.$t("mainMenu.documents"),
          link: null,
          styling: "nav-item-border-bottom",
          children: [
            {
              title: this.$t("tenant.menuItems.manuals"),
              itemIcon: "mdi-book-open-variant",
              link: "/documents/manuals",
              styling: "nav-item-border-bottom grab-home-nav-documents",
              access: "canAccessDocuments"
            },
            {
              title: this.$t("documents.menuItems.warranty"),
              itemIcon: "mdi-script",
              link: "/documents/warranty_claims",
              styling:
                "nav-item-border-bottom nav-item-border-top grab-home-nav-warranty",
              access: "canReadWarranty"
            },
            {
              title: this.$t("documents.menuItems.returnform"),
              itemIcon: "mdi-rotate-360",
              link: "/documents/return_form",
              styling:
                "nav-item-border-bottom nav-item-border-top grab-home-nav-returnForm",
              access: "canReadReturnForm"
            },
            {
              title: this.$t("documents.menuItems.serviceProtocols"),
              itemIcon: "mdi-folder-wrench-outline",
              link: "/documents/service_protocols",
              styling:
                "nav-item-border-bottom nav-item-border-top grab-home-nav-service",
              access: "canReadServiceProtocols"
            },
            {
              title: this.$t("documents.menuItems.serviceInstructions"),
              itemIcon: "mdi-tools",
              link: "/documents/service_instructions",
              styling:
                "nav-item-border-bottom nav-item-border-top grab-home-nav-service",
              access: "canReadServiceInstructions"
            },
            {
              title: this.$t("documents.menuItems.serviceManuals"),
              itemIcon: "mdi-tools",
              link: "/documents/service_manuals",
              styling:
                "nav-item-border-bottom nav-item-border-top grab-home-nav-service",
              access: "canReadServiceManuals"
            },
            {
              title: this.$t("documents.menuItems.serviceBulletins"),
              itemIcon: "mdi-tools",
              link: "/documents/service_bulletins",
              styling:
                "nav-item-border-bottom nav-item-border-top grab-home-nav-service",
              access: "canReadServiceBulletins"
            }
          ]
        },
        {
          title: this.$t("mainMenu.webshop"),
          itemIcon: "mdi-cart",
          icon: "mdi-open-in-new",
          link: null,
          action: this.onWebshopClicked,
          styling:
            "nav-item-border-bottom nav-item-border-top grab-home-nav-webshop",
          access: "canAccessWebshop"
        },
        {
          title: this.$t("mainMenu.downloads"),
          itemIcon: "mdi-download",
          link: "/downloads",
          styling:
            "nav-item-border-bottom nav-item-border-top grab-home-nav-download",
          access: "canReadDownloads"
        },
        {
          title: this.$t("mainMenu.help"),
          itemIcon: "mdi-help-circle-outline",
          icon: "mdi-open-in-new",
          link: null,
          action: this.onHelpClicked,
          styling: "grab-home-nav-help"
        },
        {
          title: this.$t("users.settings.menuItems.profile"),
          itemIcon: "mdi-account",
          link: "/user_profile",
          styling: "mt-8 grab-home-nav-user-profile"
        },
        {
          title: this.$t("users.settings.menuItems.settings"),
          itemIcon: "mdi-cog",
          link: "/user_settings",
          styling: "grab-home-nav-user-settings"
        }
      ]
    },
    messages() {
      return this.$store.getters.messages
    },
    readMessages() {
      return this.$store.getters.readMessages
    }
  },
  watch: {
    messages() {
      this.computeUnreadMessages()
    },
    readMessages() {
      this.computeUnreadMessages()
    }
  },
  methods: {
    computeUnreadMessages() {
      if (this.messages.length > 0) {
        this.numUnreadMessages = this.messages.length - this.readMessages.length
      }
    },
    expandedLink(link) {
      if (link !== null) {
        return `${link}`
      }
      return undefined
    },
    onMenuClick(item) {
      if (item.action) {
        item.action()
      }
    },
    canAccessMap() {
      return this.$store.getters.canReadMaps
    },
    canAccessMachines() {
      return this.$store.getters.canReadMachines
    },
    canAccessUsers() {
      return this.$store.getters.canReadUsers
    },
    canAccessReports() {
      return this.$store.getters.canReadReports
    },
    canReadFleetAnalytics() {
      return this.$store.getters.canReadFleetAnalytics
    },
    canAccessWebshop() {
      return this.$store.getters.canAccessWebShop
    },
    canReadWarranty() {
      return this.$store.getters.canReadWarranty
    },
    canReadReturnForm() {
      return this.$store.getters.canReadReturnForm
    },
    canReadServiceProtocols() {
      return this.$store.getters.canReadServiceProtocols
    },
    canReadServiceInstructions() {
      return this.$store.getters.canReadServiceInstructions
    },
    canReadServiceManuals() {
      return this.$store.getters.canReadServiceInstructions
    },
    canReadServiceBulletins() {
      return this.$store.getters.canReadServiceInstructions
    },
    canReadDownloads() {
      return this.$store.getters.canReadDownloads
    },
    canAccessDocuments() {
      return true
    },
    canAccess(item) {
      if (this[item.access] !== undefined) {
        return this[item.access]()
      }
      return true
    },
    onHelpClicked() {
      window.open("#/help", "_blank")
    },
    onWebshopClicked() {
      window.open(this.$store.getters.webshopURL, "_blank")
    },
    onUpdateUnreadMessages(numUnread) {
      this.numUnreadMessages = numUnread
    }
  }
}
</script>

<style>
.main-background {
  background-color: hsl(240, 1%, 24%);
  display: flex;
  align-content: stretch;
  min-height: 100%;
}
.home-background {
  background-color: hsl(120, 3%, 94%);
}
.nav-item-border-top {
  border-top: 1px solid hsl(240, 1%, 54%);
}
.nav-item-border-bottom {
  border-bottom: 1px solid hsl(240, 1%, 54%);
}
.nav-item-active {
  background-color: rgba(255, 205, 0) !important;
}
.nav-item-active .v-list-item__title {
  color: black !important;
}
</style>
