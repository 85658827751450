<template>
  <v-card
    class="pie-container"
    :class="this.legendType ? this.legendType.toString() : ''"
    ref="chartContainer"
  >
    <!-- Unprovisioned machine title -->
    <div v-if="this.disabled" class="text-center mb-0 disabled">
      <p class="pb-0 mb-0 text-uppercase text-h6 two-lines-text-truncate">
        {{ title }}
      </p>
    </div>

    <!-- Provisioned machine title -->
    <div v-else class="secondary text-center">
      <p class="text-uppercase text-h6">{{ title }}</p>
    </div>

    <div class="chart-container">
      <div
        class="d-flex align-center justify-center ma-0 pa-0 px-sm-7 overflow-y-auto overflow-x-hidden"
        style="position: absolute; width: 100%; top: 2em; left:0; bottom: 0; overflow-y: hidden !important;"
      >
        <apexchart
          type="pie"
          :width="'100%'"
          :height="apexChartHeight"
          :options="chartOptions"
          :series="series"
        />
      </div>
    </div>

    <v-overlay
      v-if="this.disabled"
      absolute
      color="#fff"
      opacity=".9"
      class="text--primary"
    >
      <h1 class="title d-flex mb-5">
        <v-icon color="primary" class="mr-2">mdi-information-outline</v-icon>
        {{ $t("machines.featureNotAvailable") }}
      </h1>
    </v-overlay>
    <div v-if="isFinite(total)" class="total">
      {{ $t("common.total") }}: {{ total }}
    </div>
  </v-card>
</template>
<script>
import VueApexCharts from "vue-apexcharts"
import colors from "vuetify/lib/util/colors"

export default {
  name: "Pie",
  props: {
    title: String,
    metricsData: Object,
    disabled: Boolean,
    formatHours: Boolean,
    total: Number,
    legendFormatter: { default: seriesName => seriesName },
    legendType: {
      validator(value) {
        return ["vertical", "horizontal"].includes(value)
      },
      default: "vertical"
    }
  },
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      apexChartWidth: 400,
      apexChartHeight: null,
      lastContainerWidth: 0
    }
  },
  // bind event handlers to the `handleResize` method (defined below)
  mounted: function() {
    window.addEventListener("resize", this.handleResize)
    this.handleResize()
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.handleResize)
  },
  methods: {
    handleResize() {
      let containerWidth = this.$refs.chartContainer.$el.clientWidth
      // if (containerWidth > 800 && this.lastContainerWidth == 800) {
      //   return
      // }
      if (Math.abs(containerWidth - this.lastContainerWidth) > 100) {
        if (containerWidth > 800) {
          containerWidth = 800
        }
        // console.log(`Container width: ${containerWidth}`)
        this.apexChartWidth = containerWidth - 25
        this.lastContainerWidth = containerWidth
      }

      if (!this.apexChartHeight) {
        this.apexChartHeight = this.$refs.chartContainer.$el.clientHeight - 40
      }
    }
  },
  computed: {
    series() {
      return this.metricsData.dataSet
    },
    chartOptions() {
      return {
        chart: {
          offsetX: 0,
          fontFamily: "Roboto, sans-serif"
        },
        noData: {
          text: this.$i18n.t("$vuetify.noDataText"),
          style: {
            fontFamily: "Roboto, sans-serif"
          }
        },
        tooltip: {
          y: this.formatHours
            ? {
                formatter: function(value) {
                  return `${Math.floor(value / 3600)} h`
                }
              }
            : {}
        },
        colors: [
          colors.orange.darken1,
          colors.green.lighten2,
          colors.blueGrey.darken3,
          colors.amber.lighten2
        ],
        dataLabels: {
          enabled: true,
          dropShadow: false,
          formatter: function(val) {
            return val.toFixed(0) + " %"
          },
          style: {
            fontFamily: "Roboto, sans-serif",
            fontWeight: 300
          }
        },
        legend: {
          show: this.series.length > 0,
          position: "right",
          markers: {
            radius: 3,
            width: 24,
            height: 10
          },
          formatter: this.legendFormatter
        },
        plotOptions: {
          pie: {
            offsetY: 0,
            customScale: 0.7,
            dataLabels: {
              offset: -15
            },
            donut: {
              size: "50%"
            }
          }
        },
        labels: this.metricsData.labels
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@mixin fit-parent-size {
  width: 100% !important;
}

.pie-container {
  --pie-reserved-width: 170;
  --pie-legend-min-width: 5.5rem;
  --pie-legend-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important; //.elevation-3
  min-height: 250px; //When changin adjust: "customScale"
  position: relative;
  overflow: hidden;
  height: 100%;
}
.pie-container::v-deep .chart-container {
  @include fit-parent-size;
  & > div {
    @include fit-parent-size;
    & > div {
      @include fit-parent-size;
      & > div {
        @include fit-parent-size;
        & > svg {
          @include fit-parent-size;
          width: 100% !important;
          height: 100% !important;
          flex-direction: row;
          & > foreignObject {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
    }
  }
}
.pie-container.horizontal {
  min-width: 360px;
}

.pie-container.vertical {
  min-width: 360px;
}

.pie-container::v-deep foreignObject {
  display: block;
}

.pie-container::v-deep .total {
  position: absolute;
  left: 10px;
  bottom: 5px;
  z-index: 100;
  font-weight: 800;
}

.pie-container::v-deep .apexcharts-legend {
  width: calc(100% - var(--pie-reserved-width) * 1px) !important;
  height: 100% !important;
  align-content: start;
  position: static !important;
  float: right;
  justify-content: center;
}

.pie-container.horizontal::v-deep .apexcharts-legend {
  display: grid;
  align-content: space-evenly;

  grid-template-columns: repeat(
    auto-fit,
    minmax(var(--pie-legend-min-width), auto)
  );
  grid-gap: 0rem;
  // display: flex;
  // flex-direction: row !important;
  // flex-flow: wrap;
}

.pie-container.vertical::v-deep .apexcharts-legend {
  display: grid;
  align-content: center;
  // display: flex;
  // flex-direction: column;
  // flex-wrap: wrap;
  // align-content: center;
  // justify-content: center;
  // min-width: var(--pie-legend-min-width);
  //max-width: calc(var(--pie-legend-min-width) + 100px);
}

.pie-container.vertical::v-deep .apexcharts-legend-series {
  min-width: var(--pie-legend-min-width);
  flex-shrink: 0;
}

.pie-container.horizontal::v-deep .apexcharts-legend-series {
  margin: 2px !important;
  flex-grow: 1;
  text-align: left;
  padding: 2px !important;
  -webkit-box-shadow: var(--pie-legend-shadow);
  box-shadow: var(--pie-legend-shadow);
  border-radius: 4px;
  display: block;
  position: relative;
  place-items: center;
  place-content: center;
}

.pie-container::v-deep .apexcharts-legend-text {
  min-width: var(--pie-legend-min-width);
}

.pie-container.horizontal::v-deep .apexcharts-legend-text {
  margin: 5px -2px;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 0px;
  display: inline-block;
  height: calc(100% - 10px);
  min-width: 2rem;
  white-space: nowrap;
  width: calc(100% + 4px);
  background-color: rgba(255, 255, 255, 0.5);
}

.pie-container.horizontal::v-deep .apexcharts-legend-marker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.pie-container.vertical::v-deep .apexcharts-legend-marker {
  min-width: 24px !important;
}
</style>
