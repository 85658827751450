export default {
  tenant: {
    contacts: {
      actions: {
        addContact: "Kontaktdaten hinzufügen",
        editContact: "Kontaktdaten bearbeiten",
        removeContact: "Kontaktdaten entfernen"
      },
      contactTypes: {
        sales: "Verkauf",
        service: "Service"
      },
      dataTable: {
        headers: {
          address: "Adresse",
          name: "Name",
          phoneNumber: "Telefonnummer",
          type: "Typ"
        }
      },
      dialogs: {
        edit: {
          title: "Kontakt bearbeiten"
        }
      },
      messages: {
        contactAdded: "Neuer Kontakt wurde hinzugefügt",
        contactUpdated: "Der Kontakt wurde aktualisiert",
        removed: "Der Kontakt wurde entfernt"
      },
      title: "Kontakte"
    },
    customer: {
      actions: {
        addCustomer: "Kundendetails hinzufügen",
        addDeliveryAddress: "Lieferadresse hinzufügen",
        editCustomer: "Kundendetails bearbeiten",
        editDeliveryAddress: "Lieferadresse bearbeiten",
        removeCustomer: "Kundendetails entfernen"
      },
      labels: {
        addDetailsQuestion:
          "Möchten Sie Kundendaten für diesen Mandanten hinzufügen? Dadurch wird eine Kundennummer für den Webshop erstellt.",
        address: "Adresse",
        addressDialogDescription:
          "Geben Sie die Daten der Lieferadresse des Kunden ein.",
        addressLine: "Adresszeile",
        customerNo: "Kundennummer",
        customerType: "Kundenart",
        dealer: "Händler",
        dealerAddressDescription:
          "Die Adresse des Händlers wird vom Webshop aus Monitor entnommen.",
        deliveryAddressDescription:
          "Adressen, an die Bestellungen im Webshop versandt werden können. Es können maximal {maxRecords} Adressen hinzugefügt werden.",
        deliveryAddressInfo:
          "Es können keine weiteren Lieferadressen hinzugefügt werden. Es wurde die maximale Anzahl von Adressen eingegeben.",
        deliveryAddresses: "Lieferadressen",
        discount: "Webshop-Rabatt in Prozent",
        endCustomer: "Endkunde",
        endCustomerName: "Name des Endkunden",
        endCustomerNo: "Endkundennummer",
        freightContact: "Kontakt für Fracht",
        missingCountry:
          "Bevor Kundendaten hinzugefügt werden können, muss ein Land angegeben werden. Bitte wählen Sie eine davon in der Ansicht des Mandantenprofils aus.",
        priceListId: "Preislisten-ID des Webshops "
      },
      messages: {
        removed: "Die Kundendaten wurden entfernt.",
        saved: "Die Kundendaten wurden gespeichert."
      },
      placeholders: {
        address: "Adresse eingeben",
        customerNo: "Monitor-Kundennummer eingeben",
        customerType: "Kundenart wählen",
        discount: "Webshop-Rabatt eingeben (0-100 %)",
        freightContact: "Frachtkontakt eingeben",
        priceListId: "Preislisten-ID des Webshops eingeben"
      },
      title: "Kunde"
    },
    dataTable: {
      headers: {
        city: "Stadt",
        country: "Land",
        description: "Beschreibung",
        name: "Name"
      }
    },
    dialogs: {
      edit: {
        addTitle: "Mieter hinzufügen",
        city: "Stadt",
        cityPlaceholder: "Geben Sie den Namen der Stadt ein",
        country: "Land",
        countryPlaceholder: "Wählen Sie das Land aus",
        description: "Beschreibung",
        descriptionPlaceholder: "Geben Sie eine Beschreibung ein",
        editTitle: "Mieter bearbeiten",
        engineHoursBeforeNextService:
          "Motorbetriebsstunden vor dem nächsten Service",
        enterEmailAddress: "Geben Sie eine E-Mail Adresse ein",
        messages: {
          added: "Der Mieter wurde hinzugefügt",
          parentChanged: "Das übergeordnete Element des Mieters wurde geändert",
          updated: "Der Mieter wurde aktualisiert"
        },
        name: "Name",
        namePlaceholder: "Geben Sie den Namen des Mieters ein",
        sendMaintanenceNotifications:
          "Senden Sie E-Mail-Benachrichtigungen zu Wartungsarbeiten."
      }
    },
    itemName: "Mieter",
    menuItems: {
      downloads: "Downloads",
      fleetAnalytics: "Fleet Analytics",
      fleet_analytics: "Dashboard: Fleet Analytics",
      machines: "Maschinen",
      manuals: "Handbücher",
      manualsProductId: "Handbücher für Brokk ",
      map: "Karte",
      reports: "Berichte",
      tenants: "Mieter",
      users: "Benutzer"
    },
    noLocationData: "Keine Standortdaten zum Anzeigen",
    profile: {
      title: "Mandantenprofil"
    },
    selectAnObject: "Bitte wählen Sie ein Objekt in der Baumansicht aus."
  }
}
